<template>
  <fm-form-dialog
    :form-title="titleText"
    :open-dialog.sync="openDialog"
    :form-parms="formParms"
    :old-data="hisData"
    form-width="800px"
    label-alone
    label-align="left"
    @formSubmit="formSubmit"
    @handleClose="handleClose">
  </fm-form-dialog>
</template>

<script>
import {
  dateOperating
} from '@/fmlib'

export default {
  name: 'DataUpdate',
  props: {
    openDialog: {
      type: Boolean
    },
    openDialogDel: {
      type: Boolean
    },
    titleText: {
      type: String
    },
    request: {
      type: Object
    },
    hisData: {
      type: Object
    },
    formParms: {
      type: Array
    },
    dealParm: {
      type: Function
    }
  },
  watch: {
    openDialogDel (value) {
      if (value) {
        this.delData()
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('handleClose')
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除' + this.titleText + '吗?'})
      if (result && this.hisData && this.hisData.id) {
        this.request.del(this.hisData.id).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '已删除',
            type: 'info'
          })
          this.$emit('updateOver', this.hisData)
        })
      }
      this.handleClose()
    },
    async formSubmit (data) {
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
        if (data[key] instanceof Array) {
          data[key] = data[key].join(',')
        }
      }
      if (this.dealParm) {
        data = await this.dealParm(data, {type: this.hisData && this.hisData.id ? 'edit' : 'add'})
      }
      if (this.hisData && this.hisData.id) {
        this.request.update(this.hisData.id, data).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '修改完成',
            type: 'success'
          })
          this.$emit('updateOver', this.hisData)
          this.handleClose()
        })
      } else {
        this.request.add(data).then((data) => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '新增完成',
            type: 'success'
          })
          if (['模块', '功能', '需求组', '需求', '数据', '数据字段', 'API', '函数', '菜单', '定时任务'].includes(this.titleText) && !data.sourceId) {
            data.sourceId = data.id
            this.request.update(data.id, data).then(() => {
              this.$emit('updateOver', this.hisData)
              this.handleClose()
            })
          } else {
            this.$emit('updateOver', this.hisData)
            this.handleClose()
          }
        })
      }
    }
  }
}
</script>
