<style lang="less">
@import './styles/datapicker.less';
@import './styles/values.less';
.fm-date-picker {
  position: relative;
  &.fm-date-picker-text {
    .fm-date-picker-input {
      border-color: transparent;
      &.fm-date-picker-focus {
        box-shadow: 0 0 0px 3px transparent;
      }
      input {
        cursor: pointer;
        transition: all .3s;
      }
      &:hover {
        border-color: transparent;
        input, input::placeholder {
          color: @color-primary;
        }
      }
    }
  }
}
.fm-date-picker-input {
  height: @size-height-norm;
  display: flex;
  border: 1px solid @color-border;
  border-radius: 4px;
  overflow: hidden;
  align-items: center;
  transition: all .2s;
  box-shadow: 0 0 0 3px transparent;
  &:hover {
    border-color: @color-primary;
  }
  &.fm-date-picker-focus {
    border-color: @color-primary;
    box-shadow: 0 0 0px 3px @color-primary-shadow;
  }
  input {
    width: 0;
    flex: 1;
    color: @color-component-text;
    padding: 0 5px;
    flex: 1;
    height: 100%;
    outline: none;
    border: none;
    font-size: @size-font-norm;
    &::placeholder {
      color: @color-placeholder-font;
    }
  }
  .fmico.fmico-error-solid, .fmico.fmico-rili {
    color: @color-placeholder-font;
    margin-right: 5px;
    transition: all .3s;
    transform: rotateX(180deg);
    transform-origin: center;
  }
  .fmico.fmico-error-solid {display: none; cursor: pointer;}
}

.fm-date-picker-clearable {
  .fm-date-picker-input:hover {
    .fmico.fmico-rili {display: none;}
    .fmico.fmico-error-solid {display: block;}
  }
}

.fm-date-picker-open {
  .fm-date-picker-box {
    pointer-events: auto;
  }
  .fm-date-picker-input {
    .fmico.fmico-rili {
      transform: rotateX(0deg);
    }
  }
}

.fm-date-picker-box {
  pointer-events: none;
  outline: none;
  display: flex;
  background-color: #FFF;
  position: fixed;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 0 5px 1px rgba(200, 200, 200, .9);
  .fm-date-picker-sidebar {
    background-color: @color-wall;
    color: @color-input-font;
    width: 92px;
    border-right: 1px solid @color-assist;
    .fm-date-picker-shortcut {
      padding: 6px 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      transition: all .2s;
      text-align: center;
      &:hover {
        background-color: #e8eaec;
      }
    }
  }
}

.fm-date-picker-absolute {
  .fm-date-picker-box {
    position: absolute;
  }
}

// 动画
.fm-date-picker-transition-enter-active, .fm-date-picker-transition-leave-active {
  transition: all .5s;
  opacity: 1;
  transform: scaleY(1) translateY(0);
}
.fm-date-picker-transition-enter, .fm-date-picker-transition-leave-to {
  opacity: 0;
  transform: scaleY(1) translateY(10px);
}
</style>

<template>
  <div class="fm-date-picker" :class="{
    'fm-date-picker-range': isRange, 'fm-date-picker-text': isText, 'fm-date-picker-open': open,
    'fm-date-picker-clearable': clearable !== false,
    'fm-date-picker-absolute': absolute
  }">
    <div class="fm-date-picker-input" :class="{
      'fm-date-picker-focus': inputFocus
    }">
      <input :value="valueText" @change="onInputChange" :placeholder="placeholder" :readonly="readonly || isMultiple || isRange || type === 'month'" ref="input" @blur="inputBlur" @keyup.enter="inputBlur" @focus="inputFocus = !readonly && true"/>
      <i class="fmico fmico-rili"></i>
      <i class="fmico fmico-error-solid" @click.stop.self="$refs.picker.clear()"></i>
    </div>
    <transition name="fm-date-picker-transition" mode="in-out">
      <div class="fm-date-picker-box" ref="box" v-show="open" tabindex="0" @blur="boxFocus = false" @focus="boxFocus = true">
        <div class="fm-date-picker-sidebar" v-if="shortcut">
          <div class="fm-date-picker-shortcut" v-for="(item, i) in shortcut" @click="shortcutChoose(item)" :key="i">{{item.text}}</div>
        </div>
        <DatePicker
          ref="picker"
          :disabledDate="disabledDate"
          :confirm="isConform"
          :template="template"
          :type="type"
          :multiple="isMultiple"
          :data="date"
          :start-date="startDate"
          @change="onChange">
        </DatePicker>
      </div>
    </transition>
  </div>
</template>

<script>
import DatePicker from './FmDatePicker/DatePicker.vue'
import { XDate } from './FmDatePicker/lib'

/**
 * - 默认选项
 * - 起始时间
 */

export default {
  name: 'FmDatePicker',
  components: {
    DatePicker
  },
  data() {
    return {
      data: null,
      date: null,
      inputFocus: false,
      boxFocus: false
    }
  },
  model: {
    prop: 'value',
    event: 'setValue'
  },
  props: {
    absolute: { type: Boolean, default: false },
    text: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'date'
    },
    confirm: {
      type: Boolean,
      default: false
    },
    startDate: {
      type: [Date, XDate],
      default: () => new XDate()
    },
    value: {
      default: null,
      type: [Date, Array, String]
    },
    multiple: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: undefined
    },
    formatter: {
      type: Function,
      default (date) {
        return date ? date.format(this.format || this.defaultFormat) : null
      }
    },
    disabledDate: {
      type: Function,
      default: undefined
    },
    shortcut: {
      type: Array,
      default: undefined
    },
    template: {
      type: Function,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isText () {
      return this.text === true || this.text === undefined
    },
    isRange () {
      return ['daterange', 'datetimerange'].includes(this.type)
    },
    isConform () {
      return this.confirm === undefined || this.confirm === true
    },
    defaultFormat () {
      if (this.type === 'date' || this.type === 'daterange') {
        return 'Y-M-D'
      } else if (this.type === 'datetime' || this.type === 'datetimerange') {
        return 'Y-M-D H:I:S'
      } else if (this.type === 'year') {
        return 'Y'
      } else if (this.type === 'month') {
        return 'M'
      } else if (this.type === 'time') {
        return 'H:I:S'
      } else {
        return 'Y-M-D H:I:S'
      }
    },
    valueText () {
      if (!this.data) {
        return ''
      } else {
        if (this.isMultiple) {
          return this.data.join(',')
        } else if (this.isRange) {
          return this.data.join(' 至 ')
        } else {
          return this.data
        }
      }
    },
    isMultiple () {
      return this.multiple === true || this.multiple === undefined
    },
    open () {
      return this.inputFocus || this.boxFocus
    }
  },
  methods: {
    onInputChange (v) {
      v = v.target.value.trim()
      if (v) {
        ([
          ['年', '/'],
          ['月', '/'],
          ['日', ' '],
          ['号', ' '],
          ['时', ':'],
          ['分', ':'],
          ['秒', '']
        ]).forEach(i => (v = v.replace(i[0], i[1])))
        v = v.replace(/\-/g, '/')
        const date = new XDate(this.type === 'time' ? ((new XDate()).format('Y/M/D ') + v) : v)
        if (isNaN(date.getTime())) {
          this.date = null
          this.data = null
        } else {
          this.date = date
          this.data = typeof this.formatter === 'function' ? this.formatter(date) : date
        }
      } else {
        this.date = null
        this.data = null
      }
      this.$emit('setValue', this.date)
    },
    clearData () {},
    inputBlur () {
      this.inputFocus = false
      // let date = new Date(this.$refs.input.value)
      // if (date.toDateString() !== 'Invalid Date') {
      // }
    },
    async shortcutChoose (shortcut) {
      let result = true
      if (typeof shortcut.onClick === 'function') {
        result = await shortcut.onClick(shortcut.text)
      }

      if (result === true || result === undefined) {
        let choose = shortcut.value(XDate)
        choose = Array.isArray(choose) ? choose : [choose]
        this.data = choose.map(v => typeof this.formatter === 'function' ? this.formatter(v) : v)
        this.date = choose.map(v => v)
      }
    },
    onChange (value) {
      if (this.isMultiple || this.isRange) {
        let values = Array.from(value.values())
        values = this.isRange ? values.splice(0, 2) : values

        this.data = values.map(v => typeof this.formatter === 'function' ? this.formatter(v.date) : v.date)
        this.date = values.map(v => v.date)
        if ((this.isConform || this.isRange) && value.size >= 2) {
          this.inputFocus = false
          this.boxFocus = false
        }
      } else if (value.size > 0) {
        const v = value.values().next().value
        if (v) {
          this.data = typeof this.formatter === 'function' ? this.formatter(v.date) : v.date
          this.date = v.date
        } else {
          this.data = null
          this.date = null
        }
        this.inputFocus = false
        this.boxFocus = false
      } else {
        this.inputFocus = false
        this.boxFocus = false
        this.data = null
        this.date = null
      }
      this.$emit('setValue', this.date)
    },
    valueChange (value) {
      let values
      if (this.isMultiple || this.isRange) {
        if (!Array.isArray(value)) {
          values = [value]
        } else {
          values = value
        }
        this.date = values.map(v => {
          if (!v) {
            return null
          } else if (typeof v === 'string') {
            return new XDate(v)
          } else if (!(v instanceof XDate)) {
            return new XDate(v.getTime())
          } else {
            return v
          }
        })
        values = null
        this.data = this.date.map(v => typeof this.formatter === 'function' ? this.formatter(v) : v)
      } else {
        if (Array.isArray(value)) {
          values = value[0]
        } else {
          values = value
        }

        if (typeof values === 'string') {
          values = new XDate(values)
        } else if (values && !(values instanceof XDate)) {
          values = new XDate(values.getTime())
        }

        this.data = values && !isNaN(values.getTime()) ? (typeof this.formatter === 'function' ? this.formatter(values) : values) : null
        this.date = values && !isNaN(values.getTime()) ? values : null
      }
    },
    updatePosition () {
      const { left, top, bottom, height } = this.$el.getBoundingClientRect()
      this.$nextTick(() => {
        const { innerWidth, innerHeight } = window
        const rect = this.$refs.box.getBoundingClientRect()

        if (this.absolute) {
          if (top > innerHeight - bottom) {
            this.$refs.box.style.top = 'unset'
            this.$refs.box.style.bottom = 'calc(100% + 10px)'
          } else {
            this.$refs.box.style.top = 'calc(100% + 10px)'
            this.$refs.box.style.bottom = 'unset'
          }
          this.$refs.box.style.left = 0
        } else {
          if (top > innerHeight - bottom) {
            this.$refs.box.style.top =  (bottom - 10 - height - rect.height) + 'px'
          } else {
            this.$refs.box.style.top = (10 + bottom) + 'px'
          }
          this.$refs.box.style.bottom = 'unset'

          if (left + rect.width > innerWidth) {
            this.$refs.box.style.left = left - (left + rect.width - innerWidth) + 'px'
          } else {
            this.$refs.box.style.left = left + 'px'
          }
        }
      })
    },
    verifier () {
      const msg = this.$verifier.check(this.data, this.required, this.rules)
      this.$emit('verifier', msg)
      return msg
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler (value) {
        this.valueChange(value)
      }
    },
    data () {
      this.$emit('change', this.data)
    },
    open (v) {
      if (v) {
        this.updatePosition()
      }
      !v && this.$nextTick(this.verifier)
    },
    inputText (v) {
      const date = new XDate(v)
      if (isNaN(date.getTime())) {
        // 
      } else {
        // 
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    // this.$nextTick(this.verifier)
    this.registerVerifier()
    document.addEventListener('scroll', this.updatePosition)
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {
    document.removeEventListener('scroll', this.updatePosition)
  },
  destroyed() {},
  errorCaptured() {}
}
</script>
