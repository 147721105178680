
function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

const state = {
  optionalData: {}
}

const getters = {
  optionalData: state => state.optionalData
}

const actions = {
  initOptionalData ({ commit, getters }, {key, getData, dataList}) {
    if (!getters.optionalData[key]) {
      let parm = {
        loading: false,
        loadNum: 0,
        getData: getData || null,
        data: dataList || []
      }
      commit('initOptionalData', {key, data: parm})
    }
  },
  async getOptionalData ({ commit, getters }, parm) {
    let key = ''
    let reload = false
    if (typeof parm === 'string') {
      key = parm
    } else {
      key = parm.key
      reload = parm.reload
    }
    let needLoad = getters.optionalData[key].getData && (reload || getters.optionalData[key].loadNum === 0)
    if (needLoad) {
      commit('setOptionalDataLoading', {key, value: true})
      let dataList = await getters.optionalData[key].getData()
      commit('setOptionalData', {key, dataList})
      commit('updateOptionalDataLoadNum', key)
      commit('setOptionalDataLoading', {key, value: false})
      return getters.optionalData[key].data
    } else {
      if (getters.optionalData[key].loading) {
        while (getters.optionalData[key].loading) {
          await sleep(200)
        }
        return getters.optionalData[key].data
      } else {
        return Promise.resolve(getters.optionalData[key].data)
      }
    }
  }
}

const mutations = {
  initOptionalData (state, {key, data}) {
    state.optionalData[key] = data
  },
  setOptionalData (state, {key, dataList}) {
    state.optionalData[key].data = dataList
  },
  setOptionalDataLoading (state, {key, value}) {
    state.optionalData[key].loading = value
  },
  updateOptionalDataLoadNum (state, key) {
    state.optionalData[key].loadNum += 1
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
