<template>
  <fm-modal
    :value="openDialog"
    width="60%"
    theme="mh-withe"
    @cancel="handleClose">
    <div style="color: #1cb5e0;" slot="header" v-if="fileParm">{{title}}</div>
    <label class="file-content"
      @drop="fileDrop"
      @dragenter="(event) => event.preventDefault()"
      @dragover="(event) => event.preventDefault()"
      @dragend="(event) => event.preventDefault()">
      <div class="file-icon">
        <svg t="1584093875056" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5246" width="44" height="44"><path d="M515.5 366.1h230.6L461 80.8v230.7c0 30 24.5 54.6 54.5 54.6zM882 681.7l-98.8-98.8c-16.3-16.3-43.1-16.3-59.4 0L625 681.7c-16.3 16.3-16.3 43.1 0 59.4 16.3 16.3 43.1 16.3 59.4 0l27.1-27.1v193.9c0 23.1 18.9 42 42 42s42-18.9 42-42V714l27.1 27.1c16.3 16.3 43.1 16.3 59.4 0 16.4-16.4 16.4-43.1 0-59.4z" p-id="5247"></path><path d="M593.9 771.5c-31.2-31.2-31.2-82.4 0-113.6l91.2-91.2c3.1-4.8 6.7-9.3 10.8-13.5 14-14 31.9-21.7 50.3-23.1v-109H507.9c-56.4 0-102.1-45.7-102.1-102.1V80.8H190.3c-27.6 0-50.2 22.6-50.2 50.2v723c0 27.6 22.6 50.2 50.2 50.2h483V791.7c-27.3 7.9-58 1.2-79.4-20.2z" p-id="5248"></path></svg>
      </div>
      <div class="file-text">点击或拖拽选中文件</div>
      <input type="file" @change="fileChange" hidden ref="input"/>
    </label>
    <div class="fm-footer">
      <fm-btn
        type="primary"
        style="margin: 22px auto 69px auto;display: inline-block;line-height: 30px;height: 30px;"
        v-loadingx="loading"
        :disabled="fileData ? false : true"
        @click="inputFileData">开始上传{{fileNameList ? `(${fileNameList})` : ''}}
      </fm-btn>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <div class="fm-footer">
        <fm-btn
          type="primary"
          v-loadingx="loading"
          :disabled="fileData ? false : true"
          @click="inputFileData">开始上传{{fileNameList ? `(${fileNameList})` : ''}}
        </fm-btn>
      </div>
    </span> -->
  </fm-modal>
</template>

<script>
import {
  fileRequest
} from '@/api'

import {
  getFullNameByFullPath
} from '@/syslib/file_conf'

export default {
  name: 'InputFileUpdate',
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    fileParm: {
      type: Object
    },
    openDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileData: null,
      inputResult: null,
      columnList: [],
      noteText: '请选择文件导入',
      loading: false
    }
  },
  computed: {
    title () {
      return this.fileParm && this.fileParm.path ? getFullNameByFullPath({
        name: this.fileParm.path.split('/')[this.fileParm.path.split('/').length - 1],
        path: this.fileParm.path
      }) : ''
    },
    fileNameList () {
      return this.fileData ? this.fileData.name : ''
    }
  },
  methods: {
    fileDrop (event) {
      event.preventDefault()
      let files = event.dataTransfer.files;
      if (files.length < 1)
          return;
      this.fileData = files[0]
    },
    clearData () {
      this.noteText = '请选择文件导入'
      this.fileData = null
      this.inputResult = null
      this.columnList = []
      this.$refs.input.value = ''
    },
    inputFileData () {
      if (!this.fileData) {
        this.$notice.info({
          title: '系统提示',
          desc: '请选择文件'
        })
        return
      }
      if (!this.fileParm) {
        this.$emit('chooseFile', this.fileData)
        return
      }
      this.loading = true
      let parm = new FormData()
      parm.append('file', this.fileData)
      parm.append('path', this.fileParm.path)
      fileRequest.upload(parm).then(() => {
        this.$emit('inputFileUpdateOver', this.fileData)
        this.$notice.success({
          title: '系统提示',
          desc: '文件上传完成'
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleClose () {
      this.clearData()
      this.$emit('handleClose')
    },
    fileChange (e) {
      let files = e.target.files || e.dataTransfer.files
      this.fileData = files[0]
    }
  }
}
</script>

<style scoped lang="less">
.file-content {
  border-radius: 5px;
  // border: 1px dashed #EEE;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  transition: all .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // &:hover {
    // background-color: #EEE;
  // }
}
.file-icon {
  background-color: #EEE;
  border-radius: 35px;
  margin-bottom: 14px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: #1cb5e0;
  }
}
.fm-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-text {
  color: #dcdddd;
  font-size: 14px;
}
</style>
