<style lang="less">
  .fm-table-body-cell-wrap {
    word-break: break-all;
  }
  .fm-table-layout-ready {
    .fm-table-body-cell-wrap {
      word-break: keep-all;
    }
  }
  .fm-table-cell {
    box-sizing: border-box;
  }
  .fm-table-cell-expand {
    transform-origin: center;
    transform: rotate(90deg);
    transition: transform .3s;
    display: inline-block;
    cursor: pointer;
  }
  .fm-table-cell-expanded {
    .fm-table-cell-expand {
      transform: rotate(180deg);
    }
  }
</style>

<template>
  <td class="fm-table-cell" :class="cellCls" @click="onClick" @contextmenu.stop.prevent="(e) => table.activeContextmenu(e, {row: row, column: column.config, area: 'body'})">
    <fm-checkbox :size="sizeSet" v-if="column.type === 'selection'" class="fm-table-section" v-model="selectionList[index]" @change="cellSelect"></fm-checkbox>
    <div class="fm-table-body-cell-wrap">
      <template v-if="column.type === 'cell'">
        <template v-if="![undefined, null, ''].includes(row[column.config.field])">{{row[column.config.field]}}</template>
        <template v-else-if="typeof emptyPlaceholder === 'function'">{{emptyPlaceholder(column.config, row)}}</template>
        <template v-else-if="typeof emptyPlaceholder === 'boolean'">—</template>
        <template v-else>{{emptyPlaceholder}}</template>
      </template>
      <template v-else-if="column.type === 'render'">
        <table-cell-render :render="column.config.render" :row="row" :column="column" :index="index"></table-cell-render>
      </template>
      <div @click="expand" v-else-if="column.type === 'expand'">
        <table-cell-render v-if="column.config.render" :render="column.config.render" :row="row" :column="column" :index="index"></table-cell-render>
        <i v-else class="fmico fmico-top-arrow fm-table-cell-expand"></i>
      </div>
      <table-cell-slot v-else-if="column.type === 'slot'" :row="row" :column="column" :index="index"></table-cell-slot>
    </div>
  </td>
</template>

<script>
import TableCellRender from './render'
import TableCellSlot from './slot'

export default {
  components: {TableCellRender, TableCellSlot},
  inject: ['table'],
  data () {
    return {
      expanded: false
    }
  },
  props: {
    column: {
      type: Object, default () {
        return {}
      }
    },
    index: {
      type: Number, default: undefined
    },
    row: {
      type: Object, default () {
        return {}
      }
    }
  },
  computed: {
    cellCls () {
      let align = this.column.config.valign || this.column.config.align || 'left'

      if (this.column.type === 'selection') {
        align = 'center'
      }

      return {
        [`fm-table-cell-align-${align}`]: true,
        'fm-table-cell-expanded': this.expanded
      }
    },
    selectionList () {
      return this.table.selectionList
    },
    sizeSet () {
      return this.table.sizeSet
    },
    emptyPlaceholder () {
      return this.table.emptyPlaceholder
    }
  },
  methods: {
    onClick () {
      this.table.$emit('on-cell-click', {index: this.index, row: this.row, column: this.column.config})
    },
    cellSelect (v) {
      this.table.$emit('cell-select', {status: v, index: this.index, row: this.row})
    },
    expand () {
      this.expanded = !this.expanded
      this.table.$emit('column-expand', {column: this.column, index: this.index, row: this.row})
    }
  }
}
</script>
