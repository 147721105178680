<template>
  <div class="fm-checkbox-group" :class="{
    'fm-checkbox-group-vertical': this.vertical !== false
  }">
    <slot></slot>
  </div>
</template>

<script>
import {findChildrenComponents} from '../basic/index'

const arrayEqual = function (arr1, arr2) {
  if (arr1 === arr2) {
    return true
  }
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false
  }
  if (arr1.length !== arr2.length) {
    return false
  }
  return arr1.every((v, i) => arr2[i] === v)
}
export default {
  name: 'FmCheckboxGroup',
  components: {},
  data () {
    return {
      data: this.value,
      children: []
    }
  },
  props: {
    keepOrder: { type: Boolean, default: false },
    value: { type: Array, default: null },
    size: {
      type: String,
      default: 'norm',
      validator: function(size) {
        return ['norm', 'large', 'small', 'mini'].includes(size)
      }
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  computed: {},
  methods: {
    updateChild () {
      let children = this.$children.filter(v => v._isVue && v.$options.name === 'FmCheckbox')
      if (children.length < 1) {
        children = findChildrenComponents(this, 'FmCheckbox')
      }
      children.forEach(checkbox => {
        checkbox.data = this.data && Array.isArray(this.data) && this.data.includes(checkbox.label)
      })
      this.children = children
    },
    appendValue (value) {
      if (!Array.isArray(this.data)) {
        this.data = []
      }
      if (this.data.length > 0 && this.keepOrder) {
        let valueIndex = this.data.map(dv => {
          return this.children.findIndex(child => child.label === dv)
        })
        let currentIndex = this.children.findIndex(child => child.label === value)
        let insert = valueIndex.findIndex(i => i >= currentIndex)
        if (insert === -1) {
          this.data.push(value)
        } else {
          this.data.splice(insert, 1, value, this.data[insert])
        }
      } else {
        this.data.push(value)
      }
    },
    rmeoveValue (value) {
      if (Array.isArray(this.data)) {
        const index = this.data.findIndex(v => v === value)
        if (index > -1) {
          this.data.splice(index, 1)
        }
      }
    }
  },
  watch: {
    data: {
      handler () {
        if (!arrayEqual(this.data, this.value)) {
          this.$emit('input', this.data)
        }
        this.$emit('change', this.data)
      },
      deep: true
    },
    value: {
      handler () {
        if (!arrayEqual(this.data, this.value)) {
          this.data = this.value
          this.$nextTick(this.updateChild)
        }
      },
      deep: true
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted () {
    this.$nextTick(this.updateChild)
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
  .fm-checkbox-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.fm-checkbox-group-vertical {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      & > .fm-checkbox {
        margin-top: 10px;
        &.fm-checkbox-large, &.fm-checkbox-norm, &.fm-checkbox-small, &.fm-checkbox-mini {
          margin-left: unset;
        }
      }
    }
  }
</style>