import Vue from 'vue'
import less from 'less'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { sync } from 'vuex-router-sync'

import App from './App.vue'
import router from './router'
import store from './store'
// import Fmui from 'fmui'
import Fmui from '@/components/fmui'
import appInit from './syslib/appInit'

import SignPage from '@/components/base/SignPage'
import SignBlock from '@/components/base/SignBlock'
import DataUpdate from '@/components/base/DataUpdate'
import InputFileUpdate from '@/components/base/InputFileUpdate'

Vue.component(SignPage.name, SignPage)
Vue.component(SignBlock.name, SignBlock)
Vue.component(DataUpdate.name, DataUpdate)
Vue.component(InputFileUpdate.name, InputFileUpdate)

Vue.config.productionTip = false
Vue.use(less)
Vue.use(Fmui)
Vue.use(ElementUI)

sync(store, router)

appInit()

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
