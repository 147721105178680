const state = {
  theme: 'dark'
}

const getters = {
  theme: state => state.theme,
  themeList: () => {
    return [
      {label: '默认主题', key: 'default', status: 0, color: 'rgba(0, 0, 0, 0)'},
      {label: '白色主题', key: 'white', status: 1, color: '#FFF'},
      {label: '深色主题', key: 'dark', status: 1, color: '#191a22'}
    ]
  }
}

const actions = {
  switchTheme ({commit}, theme) {
    commit('switchTheme', theme)
  }
}

const mutations = {
  switchTheme (state, theme) {
    state.theme = theme
  }
}

export default {
  state, getters, actions, mutations
}