<template>
  <div>
    <base-sign-block
      :title-text="titleText"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="showDataList"
      :search-parms="searchParms"
      :form-parms="formParms"
      :his-data="chooseData"
      :openDialog="openDialog"
      :no-table="noTable"
      @formHandleClose="openDialog = false"
      @formSubmit="formSubmit"
      @clickSearch="loadData"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :show-search="true"
      v-loadingx="loading"
      @tableAction="tableAction"
      :table-actions="tableActions"
      :title-menus="titleMenus"
      @clickTitleMenu="clickTitleMenu"
      :need-pagination="true">
    </base-sign-block>
  </div>
</template>

<script>
import BaseSignBlock from '@/components/base/BaseSignBlock'

import {
  dateOperating
} from '@/fmlib'

export default {
  name: 'SignBlock',
  components: {
    BaseSignBlock
  },
  props: {
    titleText: {
      type: String
    },
    noTable: {
      type: Boolean,
      default: false
    },
    initSearchData: {
      type: Object
    },
    dealFormData: {
      type: Function
    },
    loadDataAction: {
      type: String
    },
    gettersDatakey: {
      type: String
    },
    request: {
      type: Object
    },
    formParmsAdd: {
      type: Array
    },
    formParmsUpdate: {
      type: Array
    },
    titleMenus: {
      type: Array,
      default: () => {
        return [{
          key: 'add',
          label: '新增'
        }]
      }
    },
    tableActions: {
      type: Array,
      default: () => {
        return [{
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        },
        {
          key: 'files',
          label: '文件'
        }]
      }
    },
    searchParms: {
      type: Array
    },
    columnList: {
      type: Array
    }
  },
  computed: {
    showDataList: {
      get () {
        return this.gettersDatakey ? this.$store.getters[this.gettersDatakey] : this.dataList
      }
    }
  },
  created () {
    if (!this.loadDataAction) {
      this.loadData(this.initSearchData)
    }
  },
  methods: {
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    tableAction (data) {
      this.chooseData = data.data
      if (data.action === 'edit') {
        this.formParms = this.formParmsUpdate
        this.openDialog = true
      } else if (data.action === 'del') {
        this.delData()
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除' + this.titleText + '吗?'})
      if (result) {
        this.request.del(this.chooseData.id).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '已删除',
            type: 'info'
          })
          this.loadData()
        })
      }
    },
    formSubmit (data) {
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
      }
      if (this.dealFormData) {
        data = this.dealFormData(data)
        if (data === false) {
          return
        }
      }
      if (this.chooseData) {
        this.request.update(this.chooseData.id, data).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '修改完成',
            type: 'success'
          })
          this.loadData()
          this.openDialog = false
        })
      } else {
        this.request.add(data).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '新增完成',
            type: 'success'
          })
          this.loadData()
          this.openDialog = false
        })
      }
    },
    clickTitleMenu (parm) {
      this.chooseData = null
      if (parm === 'add') {
        this.formParms = this.formParmsAdd
        this.openDialog = true
      }
    },
    loadData (searchParm) {
      if (this.loadDataAction) {
        this.$store.dispatch(this.loadDataAction, searchParm)
        return
      }
      this.searchData = searchParm || this.searchData
      this.loading = true
      this.request.get(this.searchData).then((data) => {
        this.dataList = data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  data () {
    return {
      formParms: null,
      chooseList: [],
      searchData: {},
      dataList: [],
      noteText: '',
      loading: false,
      chooseData: null,
      openDialog: false,
      openDialogFile: false
    }
  }
}
</script>
