import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './modules/auth'
import User from './modules/user'
import ConfPage from './modules/conf_page'
import OptionalData from './modules/optional_data'
import BaseData from './modules/base_data'
import GlobalEvents from './modules/global_events'
import Theme from './modules/theme'
import App from './modules/app'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Auth,
    OptionalData,
    User,
    BaseData,
    GlobalEvents,
    Theme,
    ConfPage,
    App
  },
  strict: debug
})
