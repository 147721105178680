<style lang="less">
@import "./styles/values.less";
@radius: 4px;
.fm-select {
  width: 10rem;
  position: relative;
  border-radius: 4px;
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
  transition: all .2s;
  &.fm-select-block {
    width: auto;
    display: block;
  }
  &.fm-select-open {
    .fmico-top-arrow {
      transform: translateY(-50%) rotateX(0deg);
    }
    .fm-select-list {
      pointer-events: auto;
    }
  }
  .fm-select-list {
    pointer-events: none;
    z-index: 900;
    outline: none;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    transform-origin: 0 0;
    border-radius: @radius;
    .fm-select-empty {
      text-align: center;
      cursor: not-allowed;
    }
  }
  &.fm-select-absolute {
    .fm-select-list {
      position: absolute;
    }
  }
  .fmico-error-solid {
    display: none;
  }
  &.fm-select-clearable {
    &:hover {
      .fmico-error-solid {
        display: inline-block;
      }
      .fmico-top-arrow {
        display: none;
      }
    }
  }
  &.fm-select-multiple {
     .fm-select-box {
       .fm-select-value {
         box-sizing: border-box;
          display: flex;
          overflow: unset;
          flex-wrap: wrap;
          align-items: center;
          padding: 2px 0px 2px 4px;
          .fm-tag {
            font-size: 12px;
            color: #808695;
            line-height: 1.5;
            margin: 2px 4px 2px 0px;
          }
       }
     }
  }
}

.fm-select-box {
  cursor: pointer;
  position: relative;
  border-radius: @radius;
  transition: all 0.2s;
  &:focus {
    outline: none;
  }
  .fm-select-value {
    display: block;
  }
  .fm-select-input, .fm-select-value, .fm-select-placeholder, .fm-select-input {
    padding-left: 8px;
    padding-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .fm-select-input {
    width: 100%;
    border: none;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }
  .fmico-top-arrow, .fmico-error-solid {
    position: absolute;
    top: 50%;
    right: 8px;
    line-height: 1;
    transform: translateY(-50%) rotateX(180deg);
    transition: all 0.2s;
  }
  .fmico-error-solid {
    color: #808695;
  }
}

// 组件颜色设置
.fm-select.fm-select-primary {
  box-shadow: 0 0 0 3px transparent;
  &.fm-select-open {
    box-shadow: 0 0 0px 3px @color-primary-shadow;
  }
  .fm-select-box {
    border: 1px solid #dcdee2;
    &:focus,
    &:hover {
      border-color: @color-primary;
    }
    .fm-select-placeholder, .fm-select-input::placeholder {
      color: @color-placeholder-font;
    }
    .fm-select-value, .fm-select-input {
      color: @color-input-font;
    }
    .fmico-top-arrow {
      color: #808695;
    }
  }
  .fm-select-list {
    box-shadow: 0 1px 6px rgba(0,0,0,.2);
    background-color: #fff;
    .fm-select-empty {
      color: #c5c8ce;
    }
  }
  // 多选
  &.fm-select-multiple {
    .fm-option {
      &.fm-option-selected {
        background-color: #f3f3f3;
      }
    }
  }
  &.fm-select-disabled {
    .fm-select-box {
      cursor: not-allowed;
      background-color: @color-disabled-background;
      &:hover {
        border-color: #dcdee2;
      }
    }
  }
}

.fm-select.fm-select-text {
  &.fm-select-open {
    box-shadow: 0 1px 6px transparent;
  }
  .fm-select-box {
    border: 1px solid transparent;
    &:focus,
    &:hover {
      border-color: transparent;
    }
  }
}
// 验证失败颜色设置
.fm-select.fm-select-primary.verifier-error {
  .fm-select-box {
    border: 1px solid red;
    &:focus,
    &:hover {
      border-color: red;
    }
  }
}

// 组件尺寸设置
// norm
.fm-select.fm-select-norm {
  .fm-select-box {
    height: @size-height-norm;
    .fm-select-input, .fm-select-value, .fm-select-placeholder {
      line-height: @size-height-norm;
      font-size: @size-font-norm;
    }
    .fmico-top-arrow {
      font-size: @size-font-norm;
    }
  }
  &.fm-select-multiple {
    .fm-select-box {
      height: auto;
      min-height: @size-height-norm;
      .fm-select-placeholder, .fm-select-value .fm-select-input {
         line-height: 26px;
       }
    }
  }
  .fm-select-empty {
    line-height: @size-height-norm;
  }
}
// mini
.fm-select.fm-select-mini {
  .fm-select-box {
    height: @size-height-mini;
    .fm-select-input, .fm-select-value, .fm-select-placeholder {
      line-height: @size-height-mini;
      font-size: @size-font-mini;
    }
    .fmico-top-arrow {
      font-size: @size-font-mini;
    }
  }
  &.fm-select-multiple {
    .fm-select-box {
      height: auto;
      min-height: @size-height-mini;
    }
  }
  .fm-select-empty {
    line-height: @size-height-mini;
  }
}

// 动画
.fm-select-transition-enter-active, .fm-select-transition-leave-active {
  transition: all .5s;
  opacity: 1;
  transform: scaleY(1);
}
.fm-select-transition-enter, .fm-select-transition-leave-to {
  opacity: 0;
  transform: scaleY(0.7);
}
</style>

<template>
  <div class="fm-select" :class="selectClass">
    <div class="fm-select-box" :tabindex="boxTabindex" @click="toggle">
      <span class="fm-select-placeholder" v-if="!isFilterable && selectedItems.length < 1">{{placeholder}}</span>
      <span class="fm-select-value" v-else-if="isMultiple">
        <fm-tag v-for="(item, i) in selectedItems" :key="i" :closable="!isDisabled" :size="size" @close="removeSelectedValue(item.value)">{{item.label}}</fm-tag>
        <input class="fm-select-input" ref="filterable-input" :readonly="isDisabled" @keyup.stop="filterableChange" @click.stop="toggle" v-if="isFilterable" :placeholder="placeholder"/>
      </span>
      <input class="fm-select-input" ref="filterable-input" :readonly="isDisabled" @keyup.stop="filterableChange" @click.stop="toggle" v-else-if="isFilterable" :placeholder="placeholder"/>
      <span class="fm-select-value" v-else>{{selectedLabel}}</span>
      <i class="fmico fmico-top-arrow"></i>
      <i class="fmico fmico-error-solid" @click.stop.self="clearData"></i>
    </div>
    <transition name="fm-select-transition" mode="in-out">
      <div class="fm-select-list" ref="list" :tabindex="tabindex" v-show="isOpen" :style="{width: (selectListWidth > 0 ? (selectListWidth + 'px') : 'auto')}">
        <slot></slot>
        <div class="fm-select-empty" v-if="isEmpty">{{isFilterable ? '暂无匹配数据' : '暂无选项'}}</div>
      </div>
    </transition>
  </div>
</template>

<script>
function getReturn (item, withLabel) {
  if (withLabel) {
    return {
      label: item.label,
      value: item.value
    }
  } else {
    return item.value
  }
}

// 动画效果导致了option的dom点击未到达，点击触发在了fm-select-list的dom上，导致点击选项未激活而blur的事件导致了选择框的关闭
// 解决方案: 重构聚焦方案

export default {
  name: 'FmSelect',
  data() {
    return {
      isOpen: false,
      data: null,
      selectListWidth: 0,
      selectedItems: [],
      selectedValues: new Set(),
      filterableCandidate: new Set(),
      filterableValue: '',
      optionCount: 0
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    outclose: { type: Boolean, default: true },
    absolute: { type: Boolean, default: false },
    value: {
      default: null
    },
    filterable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'norm',
      validator: function(size) {
        return ['norm', 'large', 'small', 'mini'].includes(size)
      }
    },
    label: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'primary',
      validator: function(theme) {
        return ['primary'].includes(theme)
      }
    }
  },
  provide() {
    return {
      FmSelect: this
    }
  },
  computed: {
    selectedLabel () {
      if (this.selectedItems.length > 0) {
        if (this.isMultiple) {
          return this.selectedItems.map(item => item.label)
        } else {
          return this.selectedItems[0].label
        }
      } else {
        return null
      }
    },
    boxTabindex () {
      return this.isDisabled ? undefined : 0
    },
    tabindex() {
      return this.isDisabled ? undefined : 0
    },
    isFilterable () {
      return this.filterable === undefined || this.filterable === true
    },
    isClearable () {
      return this.clearable === undefined || this.clearable === true
    },
    isText () {
      return this.text === undefined || this.text === true
    },
    isBlock () {
      return this.block === undefined || this.block === true
    },
    isDisabled () {
      return this.disabled === undefined || this.disabled === true
    },
    withLabel () {
      return this.label === undefined || this.label === true
    },
    isMultiple () {
      return this.multiple === undefined || this.multiple === true
    },
    isEmpty () {
      if (this.isFilterable && this.filterableValue !== '') {
        return this.filterableCandidate.size === 0
      } else {
        return this.optionCount === 0 && this.$children.length === 0
      }
    },
    selectClass() {
      return {
        'fm-select-primary': [undefined, 'primary'].includes(this.theme),
        'fm-select-norm': [undefined, 'norm'].includes(this.size),
        'fm-select-large': 'large' === this.size,
        'fm-select-small': 'small' === this.size,
        'fm-select-mini': 'mini' === this.size,
        'fm-select-single': !this.isMultiple,
        'fm-select-multiple': this.isMultiple,
        'fm-select-open': this.isOpen,
        'fm-select-text': this.isText,
        'fm-select-block': this.isBlock,
        'fm-select-clearable': this.isClearable,
        'fm-select-disabled': this.isDisabled,
        'fm-select-absolute': this.absolute
      }
    }
  },
  methods: {
    clearData () {
      this.selectedValues.clear()
      this.onChange()
      this.$emit('clear')

      if (this.isFilterable) {
        this.filterableValue = ''
        this.$refs['filterable-input'].value = ''
        this.$refs['filterable-input'].focus()
      }
    },
    filterableChange (event) {
      this.filterableValue = event.srcElement.value

      if (this.$children) {
        this.filterableCandidate = new Set()
        this.$children.forEach(option => {
          if (option && option.$options.name === 'FmOption' && !option.isDisabled && option.label.indexOf(this.filterableValue) > -1) {
            this.filterableCandidate.add(option.label)
          }
        })
      }
    },
    addSelectedValue (value) {
      const has = this.selectedValues.has(value)
      if (this.isMultiple) {
        if (has) {
          this.removeSelectedValue(value)
        } else {
          this.selectedValues.add(value)
          this.onChange()
          if (this.isFilterable) {
            this.filterableValue = ''
            this.$refs['filterable-input'].value = ''
            this.$refs['filterable-input'].focus()
          }
        }
      } else if (!has) {
        this.selectedValues.clear()
        this.selectedValues.add(value)

        this.isOpen = false
        this.onChange()
      }
    },
    removeSelectedValue (value) {
      this.selectedValues.delete(value)
      this.onChange()
    },
    onChange () {
      this.data = this.isMultiple ? Array.from(this.selectedValues) : (this.selectedValues.size > 0 ? Array.from(this.selectedValues)[0] : null)
      this.$emit('input', this.data)
    },
    toggle() {
      if (!this.isDisabled) {
        this.isOpen = !this.isOpen
        this.selectListWidth = this.$el.getBoundingClientRect().width
      }
    },
    updatePosition () {
      const selectRect = this.$el.getBoundingClientRect()
      const height = 10 + this.$refs.list.offsetHeight
      const bottomOffset = selectRect.bottom + height
      if (window.innerHeight < bottomOffset && (window.innerHeight - selectRect.bottom < selectRect.top)) {
        if (this.absolute) {
          this.$refs.list.style.top = 'unset'
          this.$refs.list.style.bottom = 'calc(100% + 10px)'
        } else {
          this.$refs.list.style.top = (selectRect.top - height) + 'px'
          this.$refs.list.style.bottom = 'unset'
        }
      } else {
        this.$refs.list.style.top = this.absolute ? ('calc(100% + 10px)') : ((selectRect.bottom + 10) + 'px')
        this.$refs.list.style.bottom = 'unset'
      }
      this.$refs.list.style.left = this.absolute ? 0 : (selectRect.left + 'px')
    },
    updateselectedValues () {
      if (this.value !== null && this.value !== undefined) {
        this.selectedValues = this.isMultiple ? new Set(this.value) : new Set([this.value])
      }
    },
    updateSelectedItems () {
      const isArray = Array.isArray(this.data)

      let result = []
      for (let i = 0; i < this.$children.length; i++) {
        let option = this.$children[i]
        if (option && option.$options.name === 'FmOption' && !option.isDisabled) {
          if (isArray && this.data.includes(option.value)) {
            result.push(option.getData())
          } else if (option.value === this.data) {
            result.push(option.getData())
            if (!this.isMultiple) {
              break
            }
          }
        }
      }
      this.selectedItems = result
      const valueData = this.isMultiple ? this.selectedItems.map(v => getReturn(v, this.withLabel)) : (this.selectedItems.length > 0 ? getReturn(this.selectedItems[0], this.withLabel) : null)

      if (this.isFilterable && !this.isMultiple) {
        this.$refs['filterable-input'].value = this.selectedLabel
        this.filterableValue = ''
      }
      
      if (this.isMultiple) {
        this.$nextTick(this.updatePosition)
      }

      if (this.selectedItems.length === 0) {
        this.selectedValues.clear()
      }
      
      // this.verifier()
      this.$emit('change', valueData)
    },
    onMouseUp (e) {
      if (e.target !== this.$el && (e.target.contains(this.$el) || !e.path.includes(this.$el))) {
        this.isOpen = false
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.data = JSON.parse(JSON.stringify(this.value))
          this.updateselectedValues()
          this.$nextTick(this.verifier)
        })
      }
    },
    data: {
      deep: true,
      handler () {
        this.updateSelectedItems()
      }
    },
    isOpen: {
      immediate: true,
      handler (value) {
        this.$emit('visible-change', value)

        if (value && !this.isDisabled) {
          this.$nextTick(this.updatePosition)
          if (this.outclose) {
            document.body.addEventListener('mouseup', this.onMouseUp)
          }
          document.addEventListener('scroll', this.updatePosition)
        } else {
          document.removeEventListener('scroll', this.updatePosition)
          document.body.removeEventListener('mouseup', this.onMouseUp)
          if (this.isFilterable && this.$refs['filterable-input']) {
            this.filterableValue = ''
            if (!this.data || this.isMultiple) {
              this.$refs['filterable-input'].value = ''
            } else if (this.data) {
              this.$refs['filterable-input'].value = this.selectedLabel
            }
          }
        }
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.registerVerifier()
    if (this.value !== null && this.value !== undefined) {
      if (Array.isArray(this.value)) {
        this.selectedValues = new Set(this.value)
      } else {
        this.selectedValues.add(this.value)
      }
      this.onChange()
    }
  },
  beforeUpdate() {},
  updated() {
    this.optionCount = this.$children.length
  },
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed () {
    document.body.removeEventListener('mouseup', this.onMouseUp)
  },
  errorCaptured() {}
}
</script>