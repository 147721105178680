<template>
  <fm-modal
  :value="openDialog"
  :width="formWidth"
  :mask-closable="maskClosable"
  theme="mh-blackt"
  @cancel="handleClose">
    <div slot="header">{{formTitle}}</div>
    <fm-form :key="key" ref="form" :label-width="formLabelWidth" :inline="inline" :label-alone="labelAlone" :label-align="labelAlign">
      <fm-form-item v-for="item in formParms.filter(v => !v.show || v.show(formData))" :key="item.key" :label="item.label" :style="{
        width: item.type === 'multipleChoose' || formParms.length <= 4 ? '100%' : item.width,
        height: item.height
      }">
        <!-- input -->
        <template v-if="['input', 'textarea', 'password'].includes(item.type)">
          <fm-input-new
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
            style="width: 100%;"
            :type="item.type"
            @change="onValueChange(item.key)"
            :key="item.key" v-model="formData[item.key]"
            :disabled="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            :placeholder="item.placeholder"
          ></fm-input-new>
        </template>
        <!-- cmp -->
        <template v-if="item.type === 'cmp'">
          <component
            :is="item.cmp"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            v-verifier
            :type="item.type"
            @change="onValueChange(item.key)"
            :key="item.key" v-model="formData[item.key]"
            :disabled="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            :placeholder="item.placeholder">
          </component>
        </template>
        <!-- select -->
        <template v-if="item.type === 'select'">
          <fm-select
            absolute
            @change="onValueChange(item.key)" v-model="formData[item.key]" block :placeholder="item.placeholder || '请选择'" :disabled="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))" filterable
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
          >
            <fm-option
              v-for="itemO in (item.selectDatas || item.getSelectDatas(formData))"
              :key="itemO.key"
              :label="itemO.label" :value="itemO.key">
            </fm-option>
          </fm-select>
        </template>
        <!-- multipleChoose -->
        <template v-if="item.type === 'multipleChoose'">
          <fm-select
            absolute
            @change="onValueChange(item.key)" filterable :disabled="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))" v-model="formData[item.key]" multiple block
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
          >
            <fm-option v-for="option in item.selectDatas || item.getSelectDatas(formData)" :label="option.label" :value="option.key" :key="option.key"></fm-option>
          </fm-select>
        </template>
        <!-- datePicker -->
        <template v-if="item.type === 'datePicker'">
          <fm-date-picker
            absolute
            style="width: 100%;"
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            @change="onValueChange(item.key)"
            v-model="formData[item.key]"
            :readonly="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            type="date"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
            :placeholder="item.placeholder">
          </fm-date-picker>
        </template>
        <!-- datetimePicker -->
        <template v-if="item.type === 'datetimePicker'">
          <fm-date-picker
            absolute
            style="width: 100%;"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            v-verifier
            @change="onValueChange(item.key)"
            v-model="formData[item.key]"
            :readonly="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            type="datetime"
            :placeholder="item.placeholder">
          </fm-date-picker>
        </template>
        <template v-if="item.type === 'monthPicker'">
          <fm-date-picker
            absolute
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            style="width: 100%;"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
            @change="onValueChange(item.key)"
            v-model="formData[item.key]"
            :readonly="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            type="month"
            :placeholder="item.placeholder">
          </fm-date-picker>
        </template>
        <template v-if="item.type === 'daterange'">
          <fm-date-picker
          absolute
          :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
          :placeholder="item.placeholder"
          @change="onValueChange(item.key)"
          :readonly="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
          v-model="formData[item.key]"
          :required="item.check && item.check.required === true"
          :rules="item.check ? (item.check.rules || []) : []"
          v-verifier
          style="width: 100%;"
          type="daterange"></fm-date-picker>
        </template>
      </fm-form-item>
    </fm-form>
    <div class="candidate-value" v-if="candidateValue.length > 0">
      <div>可选候选值</div>
      <div @click="setCandidateValue(item)" class="item" v-for="(item, i) in candidateValue" :key="i">
        {{item.label}}: {{item.value}}
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="fm-footer">
        <fm-btn
          v-loadingx="loading"
          type="primary"
          @click="formSubmit"
          :disabled="!verifierPass">
          {{ submitBtnLabelFun(formData) || submitBtnLabel }}
        </fm-btn>
        <fm-btn
          v-loadingx="loading"
          type="info"
          tint
          style="margin-left: 5rem"
          @click="handleClose">
          取消
        </fm-btn>
      </div>
      <div class="fm-footer-wx">
        <fm-btn
          v-loadingx="loading"
          type="primary"
          @click="formSubmit"
          :disabled="!verifierPass">
          {{submitBtnLabelFun(formData) || submitBtnLabel}}
        </fm-btn>
        <fm-btn
          v-loadingx="loading"
          type="info"
          tint
          style="margin-left: 5rem"
          @click="handleClose">取消
        </fm-btn>
      </div>
    </span>
  </fm-modal>
</template>

<script>
// eslint-disable-next-line no-undef
let dcopy = require('deep-copy')

export default {
  name: 'FmFormDialog',
  components: {},
  props: {
    maskClosable: { type: Boolean, default: true },
    inline: {
      type: [Boolean, Number],
      default: false,
      validator: function (value) {
        return !(typeof value === 'number' && value < 1 && value > 4)
      }
    },
    candidateValue: {
      type: Array,
      default: () => {
        return []
      }
    },
    oldData: {
      type: Object,
      default: () => {
        return null
      }
    },
    labelAlone: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: [String, Number],
      default: null
    },
    labelAlign: {
      type: String,
      default: 'right',
      validator: function (value) {
        return ['left', 'center', 'right'].includes(value)
      }
    },
    formWidth: {
      type: String,
      default: '50%'
    },
    formTitle: {
      type: String,
      default: ''
    },
    submitBtnLabel: {
      type: String,
      default: '保存'
    },
    submitBtnLabelFun: {
      type: Function,
      default: () => {
        return false
      }
    },
    openDialog: {
      type: Boolean,
      default: false
    },
    formParms: {
      type: Array,
      default: () => {
        return []
      },
      validator: (data) => {
        let pass = true
        if (pass) {
          data.forEach((item) => {
            pass = pass && typeof item.key === 'string' && typeof item.type === 'string' && typeof item.label === 'string'
          })
        }
        return pass
      }
    },
    onChange: {
      type: Function,
      default: undefined
    }
  },
  computed: {
    formLabelWidth () {
      let args = this.formParms.map(v => isNaN(v.labelWidth) ? parseInt(v.labelWidth) : v.labelWidth).filter(v => v)
      let width = this.labelWidth !== null || !args.length ? this.labelWidth : Math.max(...args)
      return Number.isFinite(width) ? (width + 'px') : width
    }
  },
  watch: {
    openDialog (value) {
      this.key += 1
      if (value) {
        this.loading = false
        this.formData = {}
        if (this.oldData) {
          Object.keys(this.oldData).forEach(key => {
            this.$set(this.formData, key, this.oldData[key])
          })
        }
      }
    },
    oldData (value) {
      if (value) {
        this.formData = dcopy(value)
      } else {
        this.formData = {}
      }
    }
  },
  data () {
    return {
      key: 0,
      formData: {},
      verifierPass: false,
      loading: false
    }
  },
  methods: {
    verifier () {
      let noPass = this.formParms.filter(v => !v.show || v.show(this.formData)).find(item => {
        if (item.check) {
          return this.$verifier.check(this.formData[item.key], item.check.required === true, (item.check.rules || [])) !== ''
        }
      })
      // noPass && console.log(noPass.label, '验证未通过', noPass)
      this.verifierPass = noPass === undefined
    },
    onValueChange (key) {
      if (typeof this.onChange === 'function') {
        const change = this.onChange(key, this.formData[key], this.formData)
        change && Object.keys(change).forEach(key => {
          this.$set(this.formData, key, change[key])
        })
      }
      this.verifier()
    },
    setCandidateValue (item) {
      this.$set(this.formData, item.key, item.value)
    },
    handleClose () {
      this.$emit('handleClose')
    },
    async formSubmit () {
      let accept = false
      if (this.oldData && this.oldData.id) {
        for (const key of Object.keys(this.formData)) {
          if (this.formData[key] !== this.oldData[key]) {
            accept = true
            break
          }
        }
      } else {
        accept = true
      }
      if (!accept) {
        accept = await this.$dialog.confirm('数据未修改，是否继续' + (this.submitBtnLabelFun(this.formData) || this.submitBtnLabel) + '?')
      }
      if (accept) {
        this.verifier()
        if (this.verifierPass) {
          this.loading = true
          let promise = new Promise(resolve => this.$emit('formSubmit', this.formData, resolve))
          await promise
          if (this.openDialog) {
            this.loading = false
          }
        }
      }
    }
  }
}
</script>

<style scope lang="less">
@import './styles/values.less';
@import './styles/form.less';
// .fm-form-multiple-choose {
//   flex: 1;
//   height: 10rem;
//   border-radius: 0.5rem;
//   border: 1px solid @color-gray-light;
//   padding: 1rem;
//   overflow-y: auto;
//   align-items: center;
// }
// .select-fm {
//   height: 2.7rem;
//   display: flex;
//   align-items: center;
// }
// .label_text {
//   min-width: 10rem;
//   color: @color-input-label-font;
//   padding: 0 0.7rem 0 0.7rem;
//   font-size: @size-font-norm;
//   text-align: right;
//   span {
//     margin-right: 0.5rem;
//   }
// }
.fm-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fm-form-content .fm-form-item {
  margin-bottom: 25px;
}
// .fm-form-content {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
// }
// .fm-form-item {
//   width: 45%;
//   height: 3rem;
//   align-items: center;
// }
// @media all and (orientation:portrait) {
//   .fm-form-item {
//     width: 100%;
//   }
// }
.fm-footer-wx {
  display: none;
  justify-content: center;
  align-items: center;
}
@media all and (orientation:portrait) {
  .fm-footer {
    display: none;
  }
  .fm-footer-wx {
    display: flex;
  }
}
.candidate-value {
  .item {
    cursor: pointer;
  }
  .item:hover {
    color: #fe954c;
  }
}
</style>
