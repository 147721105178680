import store from '@/store'

import {
  request
} from '@/api'

export const confToPageParm = (confPageData) => {
  confPageData = typeof confPageData === 'string' ? JSON.parse(confPageData) : confPageData
  let pageParm = {
    titleMenus: confPageData.titleConf.menu,
    tableMenus: confPageData.tableConf.menu,
    searchParms: confPageData.searchConf.searchParm,
    columnList: confPageData.tableConf.column.map((v) => {
      return {
        field: v.key,
        title: v.label
      }
    }),
    loadDataRequest: null,
    title: confPageData.titleConf.title
  }
  pageParm.loadDataRequest = async function (parm) {
    let dataList = await request(confPageData.searchConf.loadDataRequest.path, confPageData.searchConf.loadDataRequest.method, parm)
    return dataList
  }
  return pageParm
}

export const dealConfPageData = (confPageData) => {
  let confPage = confPageData.conf ? JSON.parse(confPageData.conf) : {}
  confPage.title = confPageData.title || ''
  confPage.loadDataRequest = confPageData.loadDataRequest || null
  confPage.columnList = confPageData.columnList || []
  confPage.titleMenus = confPageData.titleMenus || []
  confPage.tableActions = confPageData.tableActions || []
  confPage.searchParms = confPageData.searchParms || []
  return confPage
}

const toHump = (name) => {
  // eslint-disable-next-line no-useless-escape
  return name.replace(/\_(\w)/g, function(all, letter) {
      return letter.toUpperCase()
  })
}

const generateFormParmFun = function (confColumnList) {
  let confColumn = confColumnList.filter((v) => v.conf && v.conf.edit === 1)
  confColumn.sort((a, b) => {
    return (a.conf.sort || 0) - (b.conf.sort || 0)
  })
  let getFormParms = async function () {
    let isAsync = false
    let i = 0
    let formParms = []
    while (i < confColumn.length) {
      let v = confColumn[i]
      let dataItem = {
        type: 'input',
        key: toHump(v.columnName),
        label: v.label
      }
      if (v.getOptionalValueKey) {
        dataItem.type = v.multipleChoose ? 'multiple_choose' : 'select'
        let selectDatas = await store.dispatch('getOptionalData', v.getOptionalValueKey)
        if (v.conf && v.conf.cascade) {
          dataItem.getSelectDatas = (formData) => {
            return selectDatas.filter(v1 => v1.data[toHump(v.conf.cascade.optionalDataKey)] === formData[toHump(v.conf.cascade.dataKey)])
          }
        } else {
          dataItem.selectDatas = selectDatas
        }
        isAsync = true
      }
      formParms.push(dataItem)
      i += 1
    }
    return isAsync ? formParms : Promise.resolve(formParms)
  }
  return getFormParms
}

const dealApiPath = (path, data) => {
  let l1 = path.split('{')
  let l2 = []
  l1.forEach((v) => {
    l2 = l2.concat(v.split('}'))
  })
  let newPath = ''
  let i = 0
  while (i < l2.length) {
    if (i % 2 === 0) {
      newPath += l2[i]
    } else {
      newPath += data[l2[i]]
    }
    i += 1
  }
  return newPath
}

export const dealPageConfig = async function (data) {
  let isAsync = false
  let pageConfig = {
    title: data.title,
    loadDataRequest: null,
    columnList: [],
    titleMenus: [],
    tableActions: [],
    searchParms: [],
    fileParm: {},
    countParm: [],
    showCheckbox: false
  }
  pageConfig.loadDataRequest = async function (parm) {
    let dataList = await request(dealApiPath(data.conf.loadData.api.path, parm), data.conf.loadData.api.method, parm)
    dataList.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === 'boolean') {
          item[key] = item[key] ? '1' : '0'
        }
      })
    })
    return dataList
  }
  let columnList = data.conf.loadData.api.confTable.confColumn.filter(v => v.conf && v.conf.show)
  columnList.sort((a, b) => {
    return (a.conf.sort || 0) - (b.conf.sort || 0)
  })
  pageConfig.columnList = []
  let i = 0
  while (i < columnList.length) {
    let v = columnList[i]
    let itemData = {
      field: toHump(v.columnName),
      title: v.label
    }
    if (v.getOptionalValueKey) {
      itemData.searchType = 'select'
      itemData.searchSelectDatas = await store.dispatch('getOptionalData', v.getOptionalValueKey)
      itemData.render = (h, rowData) => {
        return h('div', (itemData.searchSelectDatas.find(v2 => v2.key === rowData[itemData.field]) || {}).label)
      }
      isAsync = true
    }
    pageConfig.columnList.push(itemData)
    i += 1
  }
  let confColumn = data.conf.loadData.api.confTable.confColumn.filter(v => v.conf && v.conf.search)
  i = 0
  while (i < confColumn.length) {
    let v = confColumn[i]
    let searchConf = v.conf.searchConf || null
    let sItem = {
      type: searchConf && searchConf.inputType ? searchConf.inputType : (v.getOptionalValueKey ? 'select' : 'input'),
      key: toHump(v.columnName),
      placeholder: v.label
    }
    if (v.getOptionalValueKey) {
      sItem.selectDatas = await store.dispatch('getOptionalData', v.getOptionalValueKey)
      isAsync = true
    }
    pageConfig.searchParms.push(sItem)
    i += 1
  }
  pageConfig.titleMenus = data.conf.pageMenu
  pageConfig.titleMenus.forEach((item) => {
    if (item.api && item.api.type === 'add_data') {
      item.formConf = {
        title: item.api.confTable.label
      }
      item.formConf.toDo = {
        type: 'request',
        request: async function (parm) {
          await request(dealApiPath(item.api.path, parm), item.api.method, parm)
        }
      }
      item.formConf.getFormParms = generateFormParmFun(item.api.confTable.confColumn)
    }
  })
  pageConfig.tableMenus = data.conf.dataDmenu
  pageConfig.tableMenus.forEach((item) => {
    if (item.api && item.api.type === 'update_data') {
      item.formConf = {
        title: item.api.confTable.label
      }
      item.formConf.toDo = {
        type: 'request',
        request: async function (parm) {
          await request(dealApiPath(item.api.path, parm), item.api.method, parm)
        }
      }
      item.formConf.getFormParms = generateFormParmFun(item.api.confTable.confColumn)
    } else if (item.api && item.api.type === 'delete_data') {
      item.dealFun = async function (vm, parm) {
        let result = await vm.$dialog.confirm({title: '系统提示', content: '确定删除' + item.api.confTable.label + ' ' + parm.id + ' 吗?'})
        if (result) {
          await request(dealApiPath(item.api.path, parm), item.api.method)
          vm.$notice.info({
            title: '系统提示',
            desc: item.api.confTable.label + '已删除'
          })
          result = 'reloadData'
        }
        return result
      }
    }
  })
  return isAsync ? pageConfig : Promise.resolve(pageConfig)
}