import {
  confPageRequest,
  confApiRequest
} from '@/api'

import {
  confToPageParm
} from '@/test/norm_page'

const state = {
  confPageLoading: false,
  confPageDataList: [],
  chooseConfPage: null,
  confPageShowChoose: true,
  confApiDataList: [],
  usePageConf: {
    titleMenus: [],
    tableMenus: [],
    searchParms: [],
    columnList: [],
    loadDataRequest: null,
    title: ''
  },
  confPageData: {
    searchConf: {
      loadDataRequest: null,
      searchParm: []
    },
    titleConf: {
      title: '',
      menu: []
    },
    tableConf: {
      column: [],
      menu: []
    }
  }
}

const getters = {
  usePageConf: state => state.usePageConf,
  confPageLoading: state => state.confPageLoading,
  confPageDataList: state => state.confPageDataList,
  confPageData: state => state.confPageData,
  chooseConfPage: state => state.chooseConfPage,
  confPageShowChoose: state => state.confPageShowChoose,
  confApiDataList: state => state.confApiDataList
}

const actions = {
  loadConfApiDataList ({ commit }) {
    confApiRequest.get().then((data) => {
      commit('setConfApiDataList', data.map((v) => {
        return {
          key: v.id,
          label: v.parm,
          data: v
        }
      }))
    })
  },
  loadConfPageDataList ({ commit, dispatch }) {
    dispatch('setConfPageLoading', true)
    confPageRequest.get().then((data) => {
      commit('setConfPageDataList', data)
      dispatch('setConfPageLoading', false)
    })
  },
  saveConfPageData ({ getters, dispatch }) {
    let newData = Object.assign({}, getters.chooseConfPage)
    newData.conf = JSON.stringify(getters.confPageData)
    confPageRequest.update(getters.chooseConfPage.id, newData).then(() => {
      dispatch('loadConfPageDataList')
    })
  },
  updateUsePageConf ({ commit, getters }) {
    commit('setUsePageConf', confToPageParm(getters.confPageData))
  },
  updateConfPageData ({ commit, dispatch }, parm) {
    commit('updateConfPageData', parm)
    dispatch('updateUsePageConf')
  },
  initConfPageData ({ commit, getters, dispatch }) {
    let conf = getters.chooseConfPage.conf ? JSON.parse(getters.chooseConfPage.conf) : {}
    conf.searchConf = conf.searchConf || {
      loadDataRequest: null,
      searchParm: []
    }
    conf.titleConf = conf.titleConf || {
      title: getters.chooseConfPage.title || '',
      menu: []
    }
    conf.tableConf = conf.tableConf || {
      column: [],
      menu: []
    }
    commit('setConfPageData', conf)
    dispatch('updateUsePageConf')
  },
  setChooseConfPage ({ commit, dispatch }, data) {
    commit('setChooseConfPage', data)
    dispatch('initConfPageData')
  },
  setConfPageShowChoose ({ commit }, data) {
    commit('setConfPageShowChoose', data)
  },
  setConfPageLoading ({ commit }, data) {
    commit('setConfPageLoading', data)
  }
}

const mutations = {
  updateConfPageData (state, {keys, data}) {
    if (keys.length === 1) {
      state.confPageData[keys[0]] = data
    } else if (keys.length === 2) {
      state.confPageData[keys[0]][keys[1]] = data
    }
  },
  setConfApiDataList (state, data) {
    state.confApiDataList = data
  },
  setUsePageConf (state, data) {
    state.usePageConf = data
  },
  setConfPageData (state, data) {
    state.confPageData = data
  },
  setConfPageDataList (state, data) {
    state.confPageDataList = data
  },
  setChooseConfPage (state, data) {
    state.chooseConfPage = data
  },
  setConfPageShowChoose (state, data) {
    state.confPageShowChoose = data
  },
  setConfPageLoading (state, data) {
    state.confPageLoading = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
