<template>
  <div class="sign-page">
    <div class="sign-page-div" :class="{'sign-page-div-no-padding': noPadding}">
      <the-breadcrumb class="sign-page-top-1" v-if="breadcrumb"></the-breadcrumb>
      <fm-search-group class="sign-page-top-2" :search-parms="searchParms" @clickSearch="clickSearch" v-if="searchParms"></fm-search-group>
      <div class="sign-page-content content-block">
        <div class="content-top">
          <fm-title :title-text="titleText" :note-text="noteText" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu">
            <slot name="title"></slot>
          </fm-title>
        </div>
        <div class="content-body">
          <fm-table-new
            :simple-filter="simpleFilter"
            :columns="columns"
            v-loadingx="loading"
            border="row"
            :data-list="dataList"
            :selection="showCheckbox"
            :stripe="false"
            @on-select-change="checkDataChange"
            @counterChange="countDataChange"
            :handler-counter="countFun">
            <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
          </fm-table-new>
        </div>
      </div>
    </div>
    <fm-form-dialog
      :form-title="titleText"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="hisData"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="formHandleClose">
    </fm-form-dialog>
  </div>
</template>

<script>
import TheBreadcrumb from './TheBreadcrumb'
import TableActions from './TableActions'

export default {
  name: 'BaseSignPage',
  components: {
    TheBreadcrumb, TableActions
  },
  props: {
    fileParm: {
      type: Object
    },
    countParm: {
      type: Object
    },
    formParms: {
      type: Array
    },
    hisData: {
      type: Object
    },
    pageParm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    titleText: {
      type: String,
      default: '页面标题'
    },
    openDialog: {
      type: Boolean
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    breadcrumb: {
      type: Boolean,
      default: false
    },
    titleMenus: {
      type: Array
    },
    tableActions: {
      type: Array
    },
    countFun: {
      type: Function
    },
    searchParms: {
      type: Array
    },
    noteText: {
      type: String,
      default: '副标题'
    },
    dataList: {
      type: Array
    },
    columnList: {
      type: Array
    },
    loading: { type: Boolean, default: false },
    tableActionsWidth: { type: Number, default: null },
    tableActionsFixed: { type: Boolean, default: false },
    simpleFilter: { type: Boolean, default: true }
  },
  computed: {
    columns () {
      if (!this.tableActions || !this.tableActions.length) {
        return this.columnList
      } else {
        return [...this.columnList, {title: '操作', slot: 'actions', width: this.tableActionsWidth, fixed: this.tableActionsFixed ? 'right' : false, search: false}]
      }
    }
  },
  methods: {
    formSubmit (data) {
      this.$emit('formSubmit', data)
    },
    formHandleClose () {
      this.$emit('formHandleClose')
    },
    countDataChange (parm) {
      this.$emit('countDataChange', parm)
    },
    clickSearch (parm) {
      this.$emit('clickSearch', parm)
    },
    clickTitleMenu (parm) {
      this.$emit('clickTitleMenu', parm)
    },
    tableAction (parm) {
      this.$emit('tableAction', parm)
    },
    currentPageChange (parm) {
      this.$emit('currentPageChange', parm)
    },
    checkDataChange (parm) {
      this.$emit('checkDataChange', parm)
    }
  }
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
.content-bottom {
  height: 4.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-page-div {
  margin: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sign-page-div-no-padding {
  margin: 0;
}
.sign-page {
  width: 100%;
  height: 100%;
  display: flex;
  .fm-table-head-cells {
    background-color: #FFF;
  }
}
.sign-page-top-1 {
  margin-bottom: 1rem;
}
.sign-page-top-2 {
  margin-bottom: 1.25rem;
}
.sign-page-content {
  flex: 1;
}
.content-body {
  flex: 1;
}
</style>
