export default [
  {
    path: '/',
    name: 'app',
    component: () => import('@/views/app'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/index')
      },
      {
        path: '/page',
        name: 'page',
        component: () => import('@/views/conf'),
        children: [
          {
            path: ':id',
            name: 'page.id',
            props: true,
            component: () => import('@/views/conf/norm_page')
          }
        ]
      },
      {
        path: '/task',
        name: 'task',
        component: () => import('@/views/task')
      },
      {
        path: '/meet',
        name: 'meet',
        component: () => import('@/views/meet'),
        children: [{
          path: 'manage',
          name: 'meet.manage',
          component: () => import('@/views/meet/manage')
        },
        {
          path: 'detail',
          name: 'meet.detail',
          component: () => import('@/views/meet/detail')
        }]
      },
      {
        path: '/work',
        name: 'work',
        component: () => import('@/views/work'),
        children: [
          {
            path: 'sys',
            name: 'work.sys',
            component: () => import('@/views/work/sys'),
          },
          {
            path: 'org',
            name: 'work.org',
            component: () => import('@/views/work/org'),
          },
          {
            path: 'app',
            name: 'work.app',
            component: () => import('@/views/work/app'),
          },
          {
            path: 'deploy',
            name: 'work.deploy',
            component: () => import('@/views/work/deploy'),
          },
          {
            path: 'sys_deploy',
            name: 'work.sys_deploy',
            component: () => import('@/views/work/sys_deploy'),
          },
          {
            path: 'server',
            name: 'work.server',
            component: () => import('@/views/work/server'),
          }
        ]
      },
      {
        path: '/server',
        name: 'server',
        component: () => import('@/views/server'),
        children: [
          {
            path: 'status',
            name: 'server.status',
            component: () => import('@/views/server/status'),
          },
          {
            path: 'status/monitor',
            name: 'server.status.monitor',
            component: () => import('@/views/server/monitor/index'),
          },
          {
            path: 'server_operation_log',
            name: 'server.server_operation_log',
            component: () => import('@/views/server/server_operation_log'),
          },
          {
            path: 'server_cmd_log',
            name: 'server.server_cmd_log',
            component: () => import('@/views/server/server_cmd_log'),
          },
          {
            path: 'db_operation_log',
            name: 'server.db_operation_log',
            component: () => import('@/views/server/db_operation_log'),
          },
          {
            path: 'status',
            name: 'server.status',
            component: () => import('@/views/server/status'),
          }
        ]
      },
      {
        path: '/conf',
        name: 'conf',
        component: () => import('@/views/conf'),
        children: [
          {
            path: 'conf_table',
            name: 'conf.conf_table',
            component: () => import('@/views/conf/conf_table'),
          },
          {
            path: 'conf_column',
            name: 'conf.conf_column',
            component: () => import('@/views/conf/conf_column'),
          },
          {
            path: 'conf_api',
            name: 'conf.conf_api',
            component: () => import('@/views/conf/conf_api'),
          },
          {
            path: 'conf_page',
            name: 'conf.conf_page',
            component: () => import('@/views/conf/conf_page'),
          },
          {
            path: 'conf_query',
            name: 'conf.conf_query',
            component: () => import('@/views/conf/conf_query'),
          }
        ]
      },
      {
        path: '/file',
        name: 'file',
        component: () => import('@/views/file/index')
      },
      {
        path: '/base',
        name: 'base',
        component: () => import('@/views/base'),
        children: [
          {
            path: 'restart_type',
            name: 'base.restart_type',
            component: () => import('@/views/base/restart_type'),
          },
          {
            path: 'install_type',
            name: 'base.install_type',
            component: () => import('@/views/base/install_type'),
          },
          {
            path: 'soft',
            name: 'base.soft',
            component: () => import('@/views/base/soft'),
          },
          {
            path: 'cmd_type',
            name: 'base.cmd_type',
            component: () => import('@/views/base/cmd_type'),
          }
        ]
      },
      {
        path: '/authority',
        name: 'authority',
        component: () => import('@/views/authority'),
        children: [
          {
            path: 'user',
            name: 'authority.user',
            component: () => import('@/views/authority/user'),
          },
          {
            path: 'role',
            name: 'authority.role',
            component: () => import('@/views/authority/role'),
          },
          {
            path: 'menu',
            name: 'authority.menu',
            component: () => import('@/views/authority/menu'),
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index')
  }
]
