const state = {
  userInfo: {},
  availableMenus: [],
  availableRoles: [],
  userPrevilege: []
}

const getters = {
  userInfo: state => state.userInfo,
  userPrevilege: state => state.userPrevilege,
  availableMenus: state => state.availableMenus,
  availableRoles: state => state.availableRoles,
  userFirstPage: (state) => {
    let availableMenus = state.availableMenus
    let goMenu = null
    if (availableMenus && availableMenus.length > 0) {
      goMenu = availableMenus[0]
      if (!goMenu.url && goMenu.children && goMenu.children.length > 0) {
        goMenu = goMenu.children[0]
        if (!goMenu.url && goMenu.children && goMenu.children.length > 0) {
          goMenu = goMenu.children[0]
        }
      }
    }
    return {name: goMenu && goMenu.url ? goMenu.url : 'home'}
  },
  defaultRole: state => state.availableRoles.length > 0 ? state.availableRoles[0] : null
}

const actions = {
  setUserData ({ dispatch, getters }, data) {
    dispatch('setUserInfo', {
      name: data.name,
      id: data.id
    })
    dispatch('setUserPrevilege', data.previlege || [])
    dispatch('setAvailableMenus', data.menus.filter(v => v.clientType === getters.clientType) || [])
    dispatch('setAvailableRoles', data.roles || [])
  },
  setUserInfo ({ commit }, data) {
    commit('setUserInfo', data)
  },
  setUserPrevilege ({ commit }, data) {
    commit('setUserPrevilege', data)
  },
  setAvailableMenus ({ commit }, data) {
    commit('setAvailableMenus', data)
  },
  setAvailableRoles ({ commit }, data) {
    commit('setAvailableRoles', data)
  }
}

const mutations = {
  setUserInfo (state, data) {
    state.userInfo = data
  },
  setUserPrevilege (state, data) {
    state.userPrevilege = data
  },
  setAvailableMenus (state, data) {
    state.availableMenus = data
  },
  setAvailableRoles (state, data) {
    state.availableRoles = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
