import store from '@/store'

const confMap = {
  softFileDir: {
    path: /\/install$/,
    name: '软件文件',
    getPath: () => `/install`
  },
  sysDir: {
    path: /\/sys$/,
    name: '系统',
    getPath: () => `/sys`
  },
  orgDir: {
    path: /\/org$/,
    name: '客户',
    getPath: () => `/org`
  },
  org: {
    path: /\/org\/\d*$/,
    name: (path) => {
      let id = path.match(/[0-9]+/g)
      id = id ? id[0] : null
      let str = 'null'
      if (id) {
        str = (store.getters.orgList.find(v => v.data.id === Number(id)) || {data: {}}).data.name || 'null'
      }
      return str
    },
    getPath: (org) => `/org/${org.id}`
  },
  sys: {
    path: /\/sys\/\d*$/,
    name: (path) => {
      let id = path.match(/[0-9]+/g)
      id = id ? id[0] : null
      let str = 'null'
      if (id) {
        str = (store.getters.sysList.find(v => v.data.id === Number(id)) || {data: {}}).data.name || 'null'
      }
      return str
    },
    getPath: (sys) => `/sys/${sys.id}`
  },
  sysVersion: {
    path: /\/sys\/\d*\/\d*$/,
    name: (path) => {
      let id = path.match(/[0-9]+/g)
      id = id ? id[1] : null
      let str = 'null'
      if (id) {
        str = (store.getters.sysVersionList.find(v => v.data.id === Number(id)) || {data: {}}).data.versionNumber || 'null'
      }
      return str
    },
    getPath: (sysVersion) => `/sys/${sysVersion.sysId}/${sysVersion.id}`
  },
  sysInstanceDir: {
    path: /\/sys\/\d*\/sys_instance$/,
    name: '应用系统',
    getPath: (sys) => `/sys/${sys.id}/sys_instance`
  },
  sysInstance: {
    path: /\/sys\/\d*\/sys_instance\/\d*$/,
    name: (path) => {
      let id = path.match(/[0-9]+/g)
      id = id ? id[1] : null
      let str = 'null'
      if (id) {
        str = (store.getters.sysInstanceList.find(v => v.data.id === Number(id)) || {}).label || 'null'
      }
      return str
    },
    getPath: (sysInstance) => `/sys/${sysInstance.sysId}/sys_instance/${sysInstance.id}`
  },
  appDir: {
    path: /\/app$/,
    name: '应用',
    getPath: () => `/app`
  },
  app: {
    path: /\/app\/\d*$/,
    name: (path) => {
      let id = path.match(/[0-9]+/g)
      id = id ? id[0] : null
      let str = 'null'
      if (id) {
        str = (store.getters.appList.find(v => v.data.id === Number(id)) || {data: {}}).data.name || 'null'
      }
      return str
    },
    getPath: (app) => `/app/${app.id}`
  },
  appVersion: {
    path: /\/app\/\d*\/\d*$/,
    name: (path) => {
      let id = path.match(/[0-9]+/g)
      id = id ? id[1] : null
      let str = 'null'
      if (id) {
        str = (store.getters.appVersionList.find(v => v.data.id === Number(id)) || {data: {}}).data.versionNumber || 'null'
      }
      return str
    },
    getPath: (appVersion) => `/app/${appVersion.appId}/${appVersion.id}`
  },
  appVersionConfigDir: {
    path: /\/app\/\d*\/\d*\/config$/,
    name: '默认部署配置',
    getPath: (appVersion) => `/app/${appVersion.appId}/${appVersion.id}/config`
  },
  appVersionConfig: {
    path: /\/app\/\d*\/\d*\/config\/\d*$/,
    name: (path) => {
      let id = path.match(/[0-9]+/g)
      id = id ? id[2] : null
      let str = String(id)
      return str
    },
    getPath: (appVersionConfig) => `/app/${appVersionConfig.appId}/${appVersionConfig.appVersionId}/config/${appVersionConfig.id}`
  },
  serverDir: {
    path: /\/server$/,
    name: '服务器',
    getPath: () => `/server`
  },
  server: {
    path: /\/server\/\d*$/,
    name: (path) => {
      let id = path.match(/[0-9]+/g)
      id = id ? id[0] : null
      let str = 'null'
      if (id) {
        str = (store.getters.serverList.find(v => v.data.id === Number(id)) || {data: {}}).data.name || 'null'
      }
      return str
    },
    getPath: (server) => `/server/${server.id}`
  },
  appServiceDir: {
    path: /\/server\/\d*\/app_service$/,
    name: '服务',
    getPath: (server) => `/server/${server.id}/app_service`
  },
  appService: {
    path: /\/server\/\d*\/app_service\/\d*$/,
    name: (path) => {
      let id = path.match(/[0-9]+/g)
      id = id ? id[1] : null
      let str = 'null'
      if (id) {
        str = (store.getters.appServiceList.find(v => v.data.id === Number(id)) || {}).label || 'null'
      }
      return str
    },
    getPath: (appService) => `/server/${appService.serverId}/app_service/${appService.id}`
  },
  appServiceConfigDir: {
    path: /\/server\/\d*\/app_service\/\d*\/config$/,
    name: '部署配置',
    getPath: (appService) => `/server/${appService.serverId}/app_service/${appService.id}/config`
  },
  appServiceConfig: {
    path: /\/server\/\d*\/app_service\/\d*\/config\/\d*$/,
    name: (path) => {
      let id = path.match(/[0-9]+/g)
      id = id ? id[2] : null
      let str = String(id)
      return str
    },
    getPath: (appServiceConfig) => `/server/${appServiceConfig.serverId}/app_service/${appServiceConfig.appServiceId}/config/${appServiceConfig.id}`
  }
}

export const getNameByFullPath = (file) => {
  let name = file.name
  let key = Object.keys(confMap).find(v => confMap[v].path.test(file.path))
  if (key) {
    name = typeof confMap[key].name === 'string' ? confMap[key].name : confMap[key].name(file.path)
  }
  return name
}

export const getFullNameByFullPath = (file) => {
  let pathList = file.path.split('/')
  let nameList = []
  let i = 0
  while (i < pathList.length) {
    if (pathList[i]) {
      nameList.push(getNameByFullPath({path: pathList.slice(0, i + 1).join('/'), name: pathList[i]}))
    } else {
      nameList.push('')
    }
    i += 1
  }
  return nameList.join('/')
}

export default confMap