
const generateBasisUrl = function (url) {
  return Object.freeze({
    basUrl: url,
    parmUrl: (id) => url + `/${id}`,
  })
}

export const auth = Object.freeze({
  login: '/ucenter/oauth/token',
  getPubKey: '/ucenter/oauth/pre/token',
  getInfo: '/ucenter/users/current',
  logOut: '/ucenter/oauth/token',
  updatePwd: '/ucenter/users/myPassword'
})

export const menuApis = Object.freeze({
  parmUrl: (id) => `/ucenter/system/menus/${id}/apis`
})

export const userRole = Object.freeze({
  parmUrl: (userId) => `/ucenter/users/${userId}/roles`
})

export const resetPassword = Object.freeze({
  parmUrl: (userId) => `/ucenter/users/${userId}/password/default`
})

export const systemApis = Object.freeze({
  url: '/ucenter/system/apis'
})

export const file = Object.freeze({
  mkdir: '/fms/file/mkdir',
  upload: '/fms/upload',
  download: '/fms/download',
  del: '/fms/file'
})

export const workSysPublicKey = Object.freeze({
  get: '/work/sys/public/key'
})

export const refQuery = Object.freeze({
  get: (key) => `/work/data/ref/query/${key}`
})

export const deploy = Object.freeze({
  upload: (id) => `/work/deployment/upload/${id}`,
  restart: '/work/deployment/restart',
  ur: (id) => `/work/deployment/${id}`,
  progress: '/work/deployment/upload/progress',
  deployment: '/work/deployment',
  stop: '/work/deployment/stop',
  check: '/work/deployment/check'
})

export const user = generateBasisUrl('/ucenter/users')

export const role = generateBasisUrl('/ucenter/system/roles')

export const menu = generateBasisUrl('/ucenter/system/menus')

export const data = Object.freeze({
  get: (name) => `/work/data/${name}`,
  add: (name) => `/work/data/${name}`,
  update: (name, id) => `/work/data/${name}/${id}`,
  del: (name, id) => `/work/data/${name}/${id}`,
})

export const serverOperationLog = Object.freeze({
  get: (id) => `/work/server/operation/log/${id}`
})

export const serverSoftInstallCheck = Object.freeze({
  get: '/work/server/install/check'
})

export const serverUserPassword = Object.freeze({
  get: '/work/server/user/password'
})

export const userPassword = Object.freeze({
  getServer: '/work/server/user/password',
  getService: '/work/service/user/password',
  getSys: '/work/sys/user/password'
})

export const task = Object.freeze({
  get: '/work/task',
  count: '/work/task/total',
  details: '/work/task',
  getHis: '/work/task/total/history'
})

export const serverAppInstall = Object.freeze({
  getFileName: '/work/server/install/filename',
  getParamsConf: '/work/sys/config/params',
  install: '/work/server/install'
})

export const ucenter = Object.freeze({
  authorities: (id) => `/ucenter/users/${id}/authorities`,
  users: '/ucenter/users'
})

export const serverCmdType = Object.freeze({
  execute: '/work/server/cmd'
})

export const dataAuditLog = Object.freeze({
  base: '/eloop/data/audit/log'
})

export const workOperation = Object.freeze({
  log: '/work/operation/log'
})

export const server = Object.freeze({
  status: '/work/server/status',
  get_server_status: '/work/data/ref/query/get_server_status',
  get_server_ps_status: '/work/data/ref/query/get_server_ps_status'
})