import {
  localstorageHelper,
  tools
} from '@/fmlib'

const dealUrl = () => {
  let url = window.location.href
  if (url.split('#').length > 1) {
    let urlJ = url.split('#')[0]
    if (urlJ.includes('?')) {
      let newUrl = ''
      let newUrl1 = urlJ.split('?')[0]
      let urlJparm = urlJ.split('?')[1]
      let newUrl2 = url.split('#')[1]
      newUrl = newUrl1 + '#' + newUrl2 + '?' + urlJparm
      window.location.href = newUrl
    }
  }
}

const init = () => {
  dealUrl()
  let urlParm = tools.getUrlParm()
  if (urlParm['user_token']) {
    localstorageHelper.updateData({key: 'token', data: urlParm['user_token']})
  }
  if (urlParm['is_pop']) {
    localstorageHelper.updateData('is_pop', urlParm['is_pop'])
  }
}

export default init
