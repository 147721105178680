<style scoped lang="less">
@import url('../drag/row.less');
</style>

<template>
  <fm-modal v-model="show" @cancel="$emit('cancel')" :mask-closable="false" title="表格导出选项配置">
    <div class="fm-table-export-config" :data-moving="activeIndex !== null" @mouseenter="(e) => e.target.classList.remove('leave')" @mouseleave="(e) => e.target.classList.add('leave')">
      <div class="fm-table-export-column" ref="columns">
        <div
          class="column-item"
          v-for="(column, i) in columns"
          :key="i + column.order + column.config.title"
          :data-index="i"
          :data-field="column.config.field">
          <fm-checkbox v-model="column.hidden" :true-value="false" :false-value="true">{{column.config.title}}</fm-checkbox>
          <i class="fmico fmico-row-drage" @mousedown="onMousedown"></i>
        </div>
      </div>
    </div>
    <div slot="footer">
      <fm-btn @click="confirm" type="primary">导出</fm-btn>
      <fm-btn @click="show = false;$emit('cancel')">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>
import FmCheckbox from '../../FmCheckbox.vue'
import XLSX from 'xlsx'

let startY = 0
let timer = null

function getParentByClass (el, cls, max = 5) {
  if (el.classList.contains(cls)) {
    return el
  } else if (el.parentElement && max > 0) {
    return getParentByClass(el.parentElement, cls, max - 1)
  } else {
    return null
  }
}

export default {
  components: { FmCheckbox },
  props: {
    table: { type: Object }
  },
  data () {
    return {
      show: false,
      columns: (JSON.parse(JSON.stringify(this.table.columnConfig.columns))).filter(v => v.config.export !== false),
      activeIndex: null
    }
  },
  computed: {
    tableColumns () {
      return this.table.columnConfig.columns
    }
  },
  watch: {
    tableColumns: {
      deep: true,
      handler (v) {
        this.columns = (JSON.parse(JSON.stringify(v))).filter(v => v.config.export !== false)
      }
    }
  },
  methods: {
    confirm () {
      let columns = Array.from(this.$refs.columns.children).map(el => {
        return this.columns.find(v => v.config.field === el.dataset.field)
      }).filter(column => column && !column.hidden && column.config.export !== false).map(column => {
        if (typeof column.config.export !== 'function') {
          column.config.export = (data, column) => {
            return data[column.config.field]
          }
        }
        return column
      })

      let exportData = this.table.sortData.map(data => {
        return columns.map(column => {
          return column.config.export(data, column)
        })
      })

      exportData.unshift(columns.map(column => column.config.title))

      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet(exportData), typeof this.table.exportFileName === 'function' ? this.table.exportFileName() : this.table.exportFileName)
      XLSX.writeFile(workbook, `${workbook.SheetNames[0]}.xlsx`)

      this.$emit('done')
    },
    reset () {
      this.$refs.columns.children.forEach((el, index) => {
        el.dataset.index = index
        el.style.top = ''
        el.style.opacity = ''
        el.classList.remove('active')
      })
    },
    update (target, current) {
      let currentElement = this.$refs.columns.children[current]
      let targetElement = this.$refs.columns.children[target]

      if (targetElement === this.$refs.columns.lastElementChild) {
        this.$refs.columns.append(currentElement)
      } else if (targetElement === this.$refs.columns.firstElementChild || target < current) {
        targetElement.parentElement.insertBefore(currentElement, targetElement)
      } else if (target > current) {
        if (targetElement.nextElementSibling) {
          targetElement.parentElement.insertBefore(currentElement, targetElement.nextElementSibling)
        } else {
          this.$refs.columns.append(currentElement)
        }
      }
      this.reset()
    },
    onMousedown (e) {
      startY = e.y
      e.target.parentElement.classList.add('active')
      this.activeIndex = parseInt(e.target.parentElement.dataset.index)
      document.body.addEventListener('mousemove', this.onMousemove)
      document.body.addEventListener('mouseup', this.onMouseup)
    },
    onMousemove (e) {
      let activeElement = this.$refs.columns.children[this.activeIndex]
      activeElement.style.top = (e.y - startY) + 'px'
    },
    onMouseup (e) {
      clearTimeout(timer)

      let target = getParentByClass(e.target, 'column-item')

      document.body.removeEventListener('mousemove', this.onMousemove)
      document.body.removeEventListener('mouseup', this.onMouseup)
      
      this.$refs.columns.children[this.activeIndex].style.opacity = 0
      
      if (target) {
        timer = setTimeout((targetIndex, activeIndex) => this.update(targetIndex, activeIndex), 300, parseInt(target.dataset.index), this.activeIndex)
      } else {
        this.reset()
      }

      this.activeIndex = null
      startY = 0
    }
  }
}
</script>
