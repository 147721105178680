<template>
  <div>
    <base-sign-page
      :title-text="titleText"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="dataList"
      :search-parms="searchParms"
      :form-parms="formParms"
      :his-data="chooseData"
      :openDialog="openDialog"
      @formHandleClose="openDialog = false"
      @formSubmit="formSubmit"
      @clickSearch="loadData"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :show-search="true"
      :loading="loading"
      @tableAction="tableAction"
      :table-actions="tableActions"
      :table-actions-width="tableActionsWidth"
      :table-actions-fixed="tableActionsFixed"
      :title-menus="titleMenus"
      @clickTitleMenu="clickTitleMenu"
      :simple-filter="simpleFilter"
      :need-pagination="true">
    </base-sign-page>
    <slot></slot>
  </div>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'

import {
  dateOperating
} from '@/fmlib'

export default {
  name: 'SignPage',
  components: {
    BaseSignPage
  },
  props: {
    titleText: {
      type: String
    },
    initSearchData: {
      type: Object
    },
    request: {
      type: Object
    },
    formParmsAdd: {
      type: Array
    },
    formParmsUpdate: {
      type: Array
    },
    titleMenus: {
      type: Array,
      default: () => {
        return [{
          key: 'add',
          label: '新增'
        }]
      }
    },
    tableActions: {
      type: Array,
      default: () => {
        return [{
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }]
      }
    },
    searchParms: {
      type: Array
    },
    dealData: {
      type: Function
    },
    dealFormData: {
      type: Function
    },
    columnList: {
      type: Array
    },
    tableActionsWidth: { type: Number, default: null },
    tableActionsFixed: { type: Boolean, default: false },
    simpleFilter: { type: Boolean, default: true }
  },
  created () {
    this.loadData(this.initSearchData)
  },
  methods: {
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    tableAction (data) {
      this.chooseData = data.data
      if (data.action === 'edit') {
        this.formParms = this.formParmsUpdate
        this.openDialog = true
      } else if (data.action === 'del') {
        this.delData()
      } else {
        this.$emit('tableAction', data)
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除' + this.titleText + '吗?'})
      if (result) {
        this.request.del(this.chooseData.id).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '已删除',
            type: 'info'
          })
          this.loadData()
        })
      }
    },
    formSubmit (data) {
      if (this.dealFormData) {
        data = this.dealFormData(data)
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
        if (data[key] instanceof Array) {
          data[key] = data[key].join(',')
        }
      }
      if (this.chooseData) {
        this.request.update(this.chooseData.id, data).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '修改完成',
            type: 'success'
          })
          this.loadData()
          this.openDialog = false
        })
      } else {
        this.request.add(data).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '新增完成',
            type: 'success'
          })
          this.loadData()
          this.openDialog = false
        })
      }
    },
    clickTitleMenu (parm) {
      this.chooseData = null
      if (parm === 'add') {
        this.formParms = this.formParmsAdd
        this.openDialog = true
      }
    },
    async loadData (searchParm) {
      this.searchData = searchParm || this.searchData
      this.loading = true
      try {
        let data = await this.request.get(this.searchData)
        if (this.dealData) {
          this.dataList = await this.dealData(data)
        } else {
          this.dataList = data
        }
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    }
  },
  data () {
    return {
      formParms: [],
      chooseList: [],
      searchData: {},
      dataList: [],
      noteText: '',
      loading: true,
      chooseData: null,
      openDialog: false,
      openDialogFile: false
    }
  }
}
</script>
