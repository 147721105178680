var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-bottom":"200px"}},[_c('h2',[_vm._v("自定义插槽"),_c('fm-btn',{on:{"click":_vm.add}},[_vm._v("添加")])],1),_c('div',[_c('fm-table-new',{attrs:{"highlight-row":"","columns":_vm.columns,"data-list":_vm.data},scopedSlots:_vm._u([{key:"test",fn:function(ref){
var row = ref.row;
var column = ref.column;
var index = ref.index;
return _c('div',{},[_c('div',{attrs:{"data-row":row,"data-column":column,"data-index":index}},[_vm._v("自定义插槽")])])}}])})],1),_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_c('div',[_c('h2',[_vm._v("固定宽度")]),_c('div',{staticStyle:{"width":"500px"}},[_c('fm-table-new',{attrs:{"highlight-row":"","columns":_vm.columns,"data-list":_vm.data},scopedSlots:_vm._u([{key:"test",fn:function(ref){
var row = ref.row;
var column = ref.column;
var index = ref.index;
return _c('div',{},[_c('div',{attrs:{"data-row":row,"data-column":column,"data-index":index}},[_vm._v("自定义插槽")])])}}])})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"20px"}},[_c('h2',[_vm._v("列宽拖拽调整")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"columns":[
          {field: 'v1', resizable: true, title: 'v1'},
          {field: 'v2', resizable: true, title: 'v2'}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"20px"}},[_c('h2',[_vm._v("自动填充满父级大小")]),_c('div',{staticStyle:{"display":"flex","height":"150px","padding":"0"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"auto-height":"","columns":[
          {field: 'v1', resizable: true, title: 'v1'},
          {field: 'v2', resizable: true, title: 'v2'}
        ],"data-list":_vm.tmpData}})],1)])]),_c('h2',[_vm._v("固定列")]),_c('div',[_c('fm-table-new',{attrs:{"highlight-row":"","columns":_vm.columns2,"data-list":_vm.data2},scopedSlots:_vm._u([{key:"test",fn:function(ref){
        var row = ref.row;
        var column = ref.column;
        var index = ref.index;
return _c('div',{},[_c('div',{attrs:{"data-row":row,"data-column":column,"data-index":index}},[_vm._v("自定义插槽")])])}}])})],1),_c('h2',[_vm._v("固定表头+展开+大小调整")]),_c('div',[_c('fm-table-new',{attrs:{"sizer":"","height":200,"columns":_vm.columns,"data-list":_vm.data},scopedSlots:_vm._u([{key:"test",fn:function(ref){
        var row = ref.row;
        var column = ref.column;
        var index = ref.index;
return _c('div',{},[_c('div',{attrs:{"data-row":row,"data-column":column,"data-index":index}},[_vm._v("自定义插槽")])])}}])})],1),_c('h2',[_vm._v("固定列+固定表头")]),_c('div',[_c('fm-table-new',{attrs:{"height":200,"columns":_vm.columns2,"data-list":_vm.data2},scopedSlots:_vm._u([{key:"test",fn:function(ref){
        var row = ref.row;
        var column = ref.column;
        var index = ref.index;
return _c('div',{},[_c('div',{attrs:{"data-row":row,"data-column":column,"data-index":index}},[_vm._v("自定义插槽")])])}}])})],1),_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("自定义显示字段")]),_c('div',[_c('fm-table-new',{attrs:{"highlight-row":"","columns":_vm.columns3,"data-list":_vm.data,"custom-field":""},scopedSlots:_vm._u([{key:"test",fn:function(ref){
        var row = ref.row;
        var column = ref.column;
        var index = ref.index;
return _c('div',{},[_c('div',{attrs:{"data-row":row,"data-column":column,"data-index":index}},[_vm._v("自定义插槽")])])}}])})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"20px"}},[_c('h2',[_vm._v("可选择")]),_c('div',[_c('fm-table-new',{attrs:{"sizer":"","highlight-row":"","selection":"","columns":[
          {title: 'A', slot: 'A'},
          {title: 'B', slot: 'B'}
        ],"data-list":[].concat( new Array(3) ).map(function (v, i) {
          return {v: i}
        }),"custom-field":""},scopedSlots:_vm._u([{key:"A",fn:function(ref){
        var index = ref.index;
return _c('div',{},[_vm._v("A"+_vm._s(index))])}},{key:"B",fn:function(ref){
        var index = ref.index;
return _c('div',{},[_vm._v("A"+_vm._s(index))])}}])})],1)])]),_c('h2',[_vm._v("对齐设置")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"border":"","columns":[
      {align: 'left', field: 'v1', title: '左对齐'},
      {align: 'center', field: 'v2', title: '居中'}
    ],"data-list":_vm.tmpData}}),_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"border":"","columns":[
      {halign: 'right', field: 'v1', title: '标题右对齐'},
      {valign: 'right', field: 'v2', title: '数据右对齐'}
    ],"data-list":_vm.tmpData}}),_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"border":"","columns":[
      {halign: 'center', valign: 'right', field: 'v1', title: '标题和数据自定义对齐'},
      {align: 'center', field: 'v2', title: 'B'}
    ],"data-list":_vm.tmpData}})],1),_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("排序")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"20px"}},[_c('h2',[_vm._v("自定义排序方法")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"columns":[
          {field: 'v1', title: 'v1', sort: true, sortMethod: function (row1, row2, type) { return type === 'desc' ? row1.v1 < row2.v1 : row1.v1 > row2.v1; }},
          {field: 'v2', title: 'v2', sort: true, sortMethod: function (row1, row2, type) { return type === 'asc' ? row1.v2 > row2.v2 : row1.v2 < row2.v2; }}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"20px"}},[_c('h2',[_vm._v("组合排序"),_c('fm-tag',[_vm._v("union-sort")])],1),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"union-sort":"","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number},
          {field: 'v3', title: 'v3', sort: true, dataType: Number},
          {field: 'v4', title: 'v4', sort: true, dataType: Number}
        ],"data-list":_vm.randUnionSortData}})],1)])]),_c('h2',[_vm._v("筛选")]),_c('div',[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"columns":[
      {field: 'v1', title: 'v1', filter: true, sort: true},
      {field: 'v2', title: 'v2', filter: true, filterMultiple: false},
      {
        field: 'v3', title: 'v3', filter: true, filters: [
          {label: '包含1', value: '1'},
          {label: '包含2', value: '2'},
          {label: '包含3', value: '3'}
        ], filterMultiple: false, filterMethod: function (ref) {
            var row = ref.row;
            var column = ref.column;
            var value = ref.value;
            var type = ref.type;

            return value.indexOf(String(row.v3)) > -1;
}
      },
      {
        field: 'v4', title: 'v4', filter: true, filters: [
          {label: '包含1', value: '1'},
          {label: '包含2', value: '2'},
          {label: '包含3', value: '3'}
        ], filterMethod: function (ref) {
            var row = ref.row;
            var column = ref.column;
            var value = ref.value;
            var type = ref.type;

            return value.indexOf(String(row.v4)) > -1;
}
      },
      {field: 'v5', title: 'v5', filter: true, dataType: Number},
      {field: 'v6', title: 'v6', filter: true, dataType: Number, filterMultiple: false}
    ],"data-list":_vm.tmpData}})],1),_c('h2',[_vm._v("简易筛选")]),_c('div',[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"simple-filter":"","columns":[
      {field: 'v1', title: 'v1', filter: true, sort: true},
      {field: 'v2', title: 'v2', filter: true, filterMultiple: false},
      {
        field: 'v3', title: 'v3', filter: true, filters: [
          {label: '包含1', value: '1'},
          {label: '包含2', value: '2'},
          {label: '包含3', value: '3'}
        ], filterMultiple: false, filterMethod: function (ref) {
            var row = ref.row;
            var column = ref.column;
            var value = ref.value;
            var type = ref.type;

            return value.indexOf(String(row.v3)) > -1;
}
      },
      {
        field: 'v4', title: 'v4', filter: true, filters: [
          {label: '包含1', value: '1'},
          {label: '包含2', value: '2'},
          {label: '包含3', value: '3'}
        ], filterMethod: function (ref) {
            var row = ref.row;
            var column = ref.column;
            var value = ref.value;
            var type = ref.type;

            return value.indexOf(String(row.v4)) > -1;
}
      },
      {field: 'v5', title: 'v5', filter: true, dataType: Number},
      {field: 'v6', title: 'v6', filter: true, dataType: Number, filterMultiple: false}
    ],"data-list":_vm.tmpData}})],1),_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("斑马纹")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"stripe":"","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"10px"}},[_c('h2',[_vm._v("选择行高亮")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"highlight-row":"","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData},on:{"highlight-row-change":function($event){_vm.highlight-_vm.rowChange}}})],1)])]),_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("普通边框")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"border":"","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("行边框")]),_c('div',{staticStyle:{"display":"flex","margin-left":"10px"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"border":"row","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("列边框")]),_c('div',{staticStyle:{"display":"flex","margin-left":"10px"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"border":"col","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number},
          {field: 'v3', title: 'v3', sort: true, dataType: Number},
          {field: 'v4', title: 'v4', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)])]),_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("large")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"size":"large","border":"","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"10px"}},[_c('h2',[_vm._v("norm")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"size":"norm","border":"","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"10px"}},[_c('h2',[_vm._v("small")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"size":"small","border":"","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"10px"}},[_c('h2',[_vm._v("mini")]),_c('div',{staticStyle:{"display":"flex"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"size":"mini","border":"","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)])]),_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("合计")]),_c('div',{staticStyle:{"display":"flex","margin-left":"10px"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"summary":"","border":"col","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: String},
          {field: 'v2', title: 'v2', sort: true, dataType: String},
          {field: 'v3', title: 'v3', sort: true, dataType: Number},
          {field: 'v4', title: 'v4', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("不参与合计")]),_c('div',{staticStyle:{"display":"flex","margin-left":"10px"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"summary":"","summary-field":['v2', 'v3'],"border":"col","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number},
          {field: 'v3', title: 'v3', sort: true, dataType: Number},
          {field: 'v4', title: 'v4', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("自定义合计")]),_c('div',{staticStyle:{"display":"flex","margin-left":"10px"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"summary":"","summary-method":_vm.summaryMethod,"border":"col","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number},
          {field: 'v3', title: 'v3', sort: true, dataType: Number},
          {field: 'v4', title: 'v4', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)]),_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("指定小数位")]),_c('div',{staticStyle:{"display":"flex","margin-left":"10px"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"summary":"","summary-decimal":2,"border":"col","columns":[
          {field: 'v1', title: 'v1', sort: true, dataType: Number},
          {field: 'v2', title: 'v2', sort: true, dataType: Number},
          {field: 'v3', title: 'v3', sort: true, dataType: Number},
          {field: 'v4', title: 'v4', sort: true, dataType: Number}
        ],"data-list":_vm.tmpData}})],1)])]),_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('h2',[_vm._v("render自定义渲染")]),_c('div',{staticStyle:{"display":"flex","margin-left":"10px"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"border":"","columns":[
          {
            title: 'v1', render: function (h, row, column, index) {
              return h('fm-btn', {
                props: {type: 'primary'}
              }, index)
            }
          },
          {
            title: 'v2', render: function (h, row, column, index) {
              return h('fm-tag', {}, index)
            }
          }
        ],"data-list":_vm.tmpData}})],1)])]),_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_c('div',{staticStyle:{"flex":"1","margin-left":"20px"}},[_c('h2',[_vm._v("固定高度")]),_c('div',{staticStyle:{"display":"flex","height":"150px","padding":"0"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"border":false,"auto-height":"","full":"","columns":[
          {field: 'v1', title: 'v1'},
          {field: 'v2', title: 'v2'}
        ],"data-list":[]}})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"20px"}},[_c('h2',[_vm._v("空白提示")]),_c('div',{staticStyle:{"display":"flex","height":"150px","padding":"0"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"auto-height":"","columns":[
          {field: 'v1', title: 'v1'},
          {field: 'v2', title: 'v2'}
        ],"data-list":[]}})],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"20px"}},[_c('h2',[_vm._v("自定义空白提示")]),_c('div',{staticStyle:{"display":"flex","height":"150px","padding":"0"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"auto-height":"","columns":[
          {field: 'v1', title: 'v1'},
          {field: 'v2', title: 'v2'}
        ],"data-list":[]}},[_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_vm._v("这里啥东西都没有")])])],1)]),_c('div',{staticStyle:{"flex":"1","margin-left":"20px"}},[_c('h2',[_vm._v("无高度空白提示")]),_c('div',{staticStyle:{"display":"flex","padding":"0"}},[_c('fm-table-new',{staticStyle:{"flex":"1"},attrs:{"columns":[
          {field: 'v1', title: 'v1'},
          {field: 'v2', title: 'v2'}
        ],"data-list":[]}},[_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_vm._v("这里啥东西都没有")])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }