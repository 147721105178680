import XLSX from 'xlsx'

const checkExcelFile = (files) => {
  if (!files.length) {
    return null
  } else if (['xlsx', 'xls'].indexOf(files[0].name.split('.').slice(-1)[0]) > -1) {
    return files[0]
  } else {
    return false
  }
}

const downFile = (content, filename) => {
  let eleLink = document.createElement('a')
  eleLink.download = filename
  eleLink.style.display = 'none'
  // 字符内容转变成blob地址
  eleLink.href = URL.createObjectURL(new Blob([content]))
  // 触发点击
  document.body.appendChild(eleLink)
  eleLink.click()
  // 然后移除
  document.body.removeChild(eleLink)
}

const checkCsvFile = (files) => {
  if (!files.length) {
    return null
  } else if (['csv'].indexOf(files[0].name.split('.').slice(-1)[0]) > -1) {
    return files[0]
  } else {
    return false
  }
}

const checkImgFile = (files) => {
  if (!files.length) {
    return null
  } else if (['png', 'jpg'].indexOf(files[0].name.split('.').slice(-1)[0]) > -1) {
    return files[0]
  } else {
    return false
  }
}

const outPutExcelFile = (datas, fileName) => {
  let wb = XLSX.utils.book_new()
  datas.forEach(function (item) {
    let wsname = item.sheetName
    let ws = XLSX.utils.aoa_to_sheet(item.data)
    XLSX.utils.book_append_sheet(wb, ws, wsname)
  })
  XLSX.writeFile(wb, fileName)
}

export const outPutTableData = (dataList, columnList, fileName, sheetName) => {
  let datas = [[]]
  columnList.forEach((itemColumn) => {
    datas[0].push(itemColumn.title)
  })
  dataList.forEach((item) => {
    let dataItem = []
    columnList.forEach((itemColumn) => {
      dataItem.push(item[itemColumn.field])
    })
    datas.push(dataItem)
  })
  outPutExcelFile([{sheetName: sheetName || 'sheet1', data: datas}], fileName)
}

const readExcelFileData = ({file, sheets, type, allsheets}) => {
  return new Promise(function (resolve, reject) {
    let fileReader = new FileReader()
    fileReader.onload = function (e) {
      try {
        let dataMap = {}
        let data = e.target.result
        let wb = XLSX.read(data, {
          type: 'binary'
        })
        // 获取所有sheet数据
        if (allsheets) {
          wb.SheetNames.forEach(function (sheetItem) {
            let delFun = type === 'row_object_array' ? XLSX.utils.sheet_to_row_object_array : XLSX.utils.sheet_to_csv
            let sheetDatas = delFun(wb.Sheets[sheetItem])
            dataMap[sheetItem] = type === 'row_object_array' ? dealSheetDataToMap(sheetDatas, sheets[0].needDataKeys) : dealSheetDataToList(sheetDatas)
          })
          resolve(dataMap)
        } else if (testList1ContainList2({list1: wb.SheetNames, list2: sheets, list2Key: 'name'})) {
          sheets.forEach(function (item) {
            let sheetDatas = XLSX.utils.sheet_to_row_object_array(wb.Sheets[item.name])
            dataMap[item.key] = dealSheetDataToMap(sheetDatas, item.needDataKeys)
          })
          resolve(dataMap)
        } else {
          reject(new Error('导入数据格式不规范！'))
        }
      } catch (error) {
        reject(error)
      }
    }
    fileReader.readAsBinaryString(file)
  })
}

const readCsvFileData = (file) => {
  return new Promise(function (resolve, reject) {
    let fileReader = new FileReader()
    fileReader.onload = function (e) {
      console.log('read over')
      try {
        let dataMap = {}
        let data = e.target.result
        console.log('开始读取数据', data)
        let wb = XLSX.read(data, {
          type: 'binary'
        })
        console.log('wb')
        console.log(wb, 'wb')
        // 获取所有sheet数据
        console.log('开始解析数据')
        wb.SheetNames.forEach(function (sheetItem) {
          console.log(wb.Sheets[sheetItem])
        })
        console.log('解析完成')
        resolve(dataMap)
      } catch (error) {
        console.log(error, 'error')
        reject(error)
      }
    }
    fileReader.readAsBinaryString(file)
  })
}

const dealSheetDataToMap = (sheetDatas, needDataKeys) => {
  let newDataList = []
  sheetDatas.forEach(function (itemMap) {
    let newData = {}
    needDataKeys.forEach(function (itemKey) {
      let value = itemKey.key ? itemMap[itemKey.key] : itemMap[itemKey]
      newData[itemKey] = itemKey.delData ? itemKey.formatData(value) : value
    })
    newDataList.push(newData)
  })
  return newDataList
}

const dealSheetDataToList = (sheetDatas) => {
  let newDataList = []
  sheetDatas.split('\n').forEach(function (itemStr) {
    newDataList.push(itemStr.split(','))
  })
  return newDataList
}

const testList1ContainList2 = ({list1, list2, list2Key}) => {
  let contain = true
  list2.forEach(function (item) {
    if (list1.indexOf(list2Key ? item[list2Key] : item) === -1) {
      contain = false
    }
  })
  return contain
}

export default {
  downFile,
  checkExcelFile,
  checkCsvFile,
  checkImgFile,
  outPutExcelFile,
  outPutTableData,
  readExcelFileData,
  readCsvFileData
}