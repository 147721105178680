import Vue from 'vue'

const state = {}

const getters = {}

const actions = {
  showNoticeMsg ({ commit }, data) {
    commit('setNoticeMsg', data)
  }
}

const mutations = {
  setNoticeMsg (state, data) {
    Vue.prototype.$notice[data.type || 'info'](data.title || '系统提示', data.message)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
