import {
  appServiceRequest,
  sysInstanceRequest
} from '@/api'

const state = {
  sysListUrls: [],
  serviceListUrls: []
}

const getters = {
  sysListUrls: state => state.sysListUrls,
  serviceListUrls: state => state.serviceListUrls
}

const actions = {
  // 获取应用系统地址
  async loadSysUrls ({ commit, state }, refresh = true) {
    if (refresh || !state.sysListUrls.length) {
      let res = await sysInstanceRequest.geturl()
      commit('setSysUrls', res)
      return res
    } else {
      return state.sysListUrls
    }
  },
  // 获取服务地址
  async loadServiceUrls ({ commit, state }, refresh = true) {
    if (refresh || !state.serviceListUrls.length) {
      let res = await appServiceRequest.geturl()
      commit('setServiceUrls', res)
      return res
    } else {
      return state.serviceListUrls
    }
  }
}

const mutations = {
  setSysUrls (state, data) {
    state.sysListUrls = data
  },
  setServiceUrls (state, data) {
    state.serviceListUrls = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
