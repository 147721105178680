import {
  axiosInit
} from '@/fmlib'
import * as url from './url'

import sysEnv from '@/env/env'

import store from '@/store'

import Vue from 'vue'

export const baseUrl = sysEnv.apiAdr

let showMessageFun = (msg) => {
  Vue.prototype.$notice.error({
    title: '系统提示',
    desc: msg
  })
}

let showMessageFunNoNotice = (msg) => {
  console.log('api调用异常:', msg)
}

export const getTokenFun = () => {
  return store.getters.token
}

let axiosInitOne = new axiosInit.axiosInit({baseUrl, showMessageFun, getTokenFun})
let axiosInitOneNoNotice = new axiosInit.axiosInit({baseUrl, showMessageFun: showMessageFunNoNotice, getTokenFun})

export const request = axiosInitOne.request
export const requestNoNotice = axiosInitOneNoNotice.request

let httpRequestMethods = axiosInitOne.httpRequestMethods

export const generateBasisRequest = function (urlParm) {
  return {
    get: function (parm) {
      return request(urlParm.basUrl, httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(urlParm.basUrl, httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(urlParm.parmUrl(id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(urlParm.parmUrl(id), httpRequestMethods.DELETE)
    }
  }
}

export const generateBasisDataRequest = function (name) {
  return {
    get: function (parm) {
      return request(url.data.get(name), httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(url.data.add(name), httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(url.data.update(name, id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(url.data.del(name, id), httpRequestMethods.DELETE)
    }
  }
}

// file
export const fileRequest = {
  mkdir: function (parm) {
    return request(url.file.mkdir, httpRequestMethods.POST, parm)
  },
  upload: function (parm) {
    return request(url.file.upload, httpRequestMethods.POST, parm, {
      contentType: 'multipart/form-data'
    })
  },
  get: function (parm) {
    return requestNoNotice(url.file.download, httpRequestMethods.GET, parm)
  },
  download: function (parm) {
    return request(url.file.download, httpRequestMethods.GET, parm, {
      responseType: 'blob'
    })
  },
  del: function (parm) {
    return request(url.file.del, httpRequestMethods.DELETE, parm)
  }
}

// workSysPublicKey
export const workSysPublicKeyRequest = {
  get: function () {
    return request(url.workSysPublicKey.get, httpRequestMethods.GET)
  }
}

export const refQueryRequestHander = async function (key, parm) {
  return await request(url.refQuery.get(key), httpRequestMethods.GET, parm)
}

// systemApis
export function systemApisGet () {
  return request(url.systemApis.url, httpRequestMethods.GET)
}

// menuApis
export function menuApisUpate (id, parm) {
  return request(url.menuApis.parmUrl(id), httpRequestMethods.POST, parm)
}

export const userRequest = generateBasisRequest(url.user)

export const roleRequest = generateBasisRequest(url.role)

export const menuRequest = generateBasisRequest(url.menu)

// auth
export function login (parm) {
  return request(url.auth.login, httpRequestMethods.POST, parm)
}

export function getLoginPubKey (parm) {
  return request(url.auth.getPubKey, httpRequestMethods.POST, parm)
}

export function updatePwd (parm) {
  return request(url.auth.updatePwd, httpRequestMethods.PUT, parm)
}

export function userLogOut () {
  return request(url.auth.logOut, httpRequestMethods.DELETE)
}

export function getUserInfo () {
  return request(url.auth.getInfo, httpRequestMethods.GET)
}

// resetPassword
export function resetPassword (id) {
  return request(url.resetPassword.parmUrl(id), httpRequestMethods.PUT)
}

// sys
export const sysRequest = Object.assign(generateBasisDataRequest('Sys'), {
  get (parm) {
    return refQueryRequestHander('get_sys', parm)
  }
})

export const orgRequest = generateBasisDataRequest('Org')

// sysVersion
export const sysVersionRequest = Object.assign(generateBasisDataRequest('SysVersion'), {
  get (parm) {
    return refQueryRequestHander('get_sys_version', parm)
  }
})

// sysDemandGroup
export const sysDemandGroupRequest = generateBasisDataRequest('SysDemandGroup')

// sysDemand
export const sysDemandRequest = generateBasisDataRequest('SysDemand')

// sysModule
export const sysModuleRequest = generateBasisDataRequest('SysModule')

// sysFeatures
export const sysFeaturesRequest = generateBasisDataRequest('SysFeatures')

// sysApp
export const sysAppRequest = Object.assign(generateBasisDataRequest('Sys2App'), {
  get (parm) {
    return refQueryRequestHander('get_sys_app', parm)
  }
})

// app
export const appRequest = Object.assign(generateBasisDataRequest('App'), {
  get (parm) {
    return refQueryRequestHander('get_app', parm)
  }
})

// appVersion
export const appVersionRequest = Object.assign(generateBasisDataRequest('AppVersion'), {
  get (parm) {
    return refQueryRequestHander('get_app_version', parm)
  },
  getSysDepot  (parm) {
    return refQueryRequestHander('get_sys_deploy_app_version', parm)
  }
})

// appVersionSoft
export const appVersionSoftRequest = Object.assign(generateBasisDataRequest('AppVersionSoft'), {
  get (parm) {
    return refQueryRequestHander('get_app_version_soft', parm)
  }
})

// appVersionConfig
export const appVersionConfigRequest = Object.assign(generateBasisDataRequest('AppVersionConfig'), {
  get (parm) {
    return refQueryRequestHander('get_app_version_config', parm)
  }
})

// appService
export const appServiceRequest = Object.assign(generateBasisDataRequest('AppService'), {
  get (parm) {
    return refQueryRequestHander('get_app_service', parm)
  },
  geturl (parm) {
    return refQueryRequestHander('get_app_service_url', parm)
  }
})

// appServiceConfig
export const appServiceConfigRequest = Object.assign(generateBasisDataRequest('AppServiceConfig'), {
  get (parm) {
    return refQueryRequestHander('get_app_service_config', parm)
  },
  getRestartLog (parm) {
    return refQueryRequestHander('get_app_service_restart', parm)
  },
  getUploadLog (parm) {
    return refQueryRequestHander('get_app_service_upload', parm)
  }
})

// appServiceUser
export const appServiceUserRequest = Object.assign(generateBasisDataRequest('AppServiceUser'), {
  get (parm) {
    return refQueryRequestHander('get_app_service_user', parm)
  }
})

// server
export const serverRequest = Object.assign(generateBasisDataRequest('Server'), {
  get (parm) {
    return refQueryRequestHander('get_server', parm)
  },
  status (parm) {
    return request(url.server.status, httpRequestMethods.GET, parm)
  },
  get_server_status (parm) {
    return request(url.server.get_server_status, httpRequestMethods.GET, parm)
  },
  get_server_ps_status (parm) {
    return request(url.server.get_server_ps_status, httpRequestMethods.GET, parm)
  }
})

// serverConfiguration
export const serverConfigurationRequest = generateBasisDataRequest('ServerConfiguration')

// serverOperationLog
export const serverOperationLogRequest = {
  get (id) {
    return request(url.serverOperationLog.get(id), httpRequestMethods.GET)
  }
}

// serverSoft
export const serverSoftRequest = Object.assign(generateBasisDataRequest('ServerSoft'), {
  get (parm) {
    return refQueryRequestHander('get_server_soft', parm)
  }
})

// serverSoftInstallCheck
export const serverSoftInstallCheckRequest = {
  get (parm) {
    return request(url.serverSoftInstallCheck.get, httpRequestMethods.GET, parm)
  }
}
// serverConfig
export const serverConfigRequest = generateBasisDataRequest('ServerConfig')

// serverNetwork
export const serverNetworkRequest = generateBasisDataRequest('ServerNetwork')

// serverFirewall
export const serverFirewallRequest = generateBasisDataRequest('ServerFirewall')

// serverUser
export const serverUserRequest = Object.assign(generateBasisDataRequest('ServerUser'), {
  get (parm) {
    return refQueryRequestHander('get_server_user', parm)
  }
})

export const userPasswordRequest = {
  getServer (parm) {
    return request(url.userPassword.getServer, httpRequestMethods.POST, parm)
  },
  getService (parm) {
    return request(url.userPassword.getService, httpRequestMethods.POST, parm)
  },
  getSys (parm) {
    return request(url.userPassword.getSys, httpRequestMethods.POST, parm)
  }
}

// serverAppInstall
export const serverAppInstallRequest = {
  getFileName () {
    return request(url.serverAppInstall.getFileName, httpRequestMethods.GET)
  },
  getParamsConf () {
    return request(url.serverAppInstall.getParamsConf, httpRequestMethods.GET)
  },
  install (parm) {
    return request(url.serverAppInstall.install, httpRequestMethods.POST, parm)
  }
}

// sysInstance
export const sysInstanceRequest = Object.assign(generateBasisDataRequest('SysInstance'), {
  get (parm) {
    return refQueryRequestHander('get_sys_instance', parm)
  },
  geturl (parm) {
    return refQueryRequestHander('get_sys_instance_url', parm)
  }
})

// sysInstanceUser
export const sysInstanceUserRequest = Object.assign(generateBasisDataRequest('SysInstanceUser'), {
  get (parm) {
    return refQueryRequestHander('get_sys_instance_user', parm)
  }
})

// sysInstanceMaintainRecord
export const sysInstanceMaintainRecordRequest = generateBasisDataRequest('SysInstanceMaintainRecord')

// soft
export const softRequest = generateBasisDataRequest('Soft')

// restartType
export const restartTypeRequest = Object.assign(generateBasisDataRequest('RestartType'), {
  get (parm) {
    return refQueryRequestHander('get_restart_type', parm)
  }
})

// installType
export const installTypeRequest = generateBasisDataRequest('InstallType')

// confTable
export const confTableRequest = generateBasisDataRequest('ConfTable')

// confColumn
export const confColumnRequest = generateBasisDataRequest('ConfColumn')

// confApi
export const confApiRequest = generateBasisDataRequest('ConfApi')

// confPage
export const confPageRequest = generateBasisDataRequest('ConfPage')

// confQuery
export const confQueryRequest = generateBasisDataRequest('ConfQuery')

// deploy
export const deployRequest = {
  upload: function (id, file) {
    let parm = new FormData()
    let key = typeof file === 'string' ? 'path' : 'file'
    parm.append(key, file)
    return request(url.deploy.upload(id), httpRequestMethods.POST, parm, {
      contentType: 'multipart/form-data'
    })
  },
  restart: function (parm) {
    return request(url.deploy.restart, httpRequestMethods.POST, parm)
  },
  check: function (parm) {
    return request(url.deploy.check, httpRequestMethods.GET, parm)
  },
  stop: function (parm) {
    return request(url.deploy.stop, httpRequestMethods.POST, parm)
  },
  deployment: function (parm) {
    return request(url.deploy.deployment, httpRequestMethods.GET, parm)
  },
  ur: function (id, file) {
    let parm = new FormData()
    let key = typeof file === 'string' ? 'path' : 'file'
    parm.append(key, file)
    return request(url.deploy.ur(id), httpRequestMethods.POST, parm, {
      contentType: 'multipart/form-data'
    })
  },
  progress: function (parm) {
    return request(url.deploy.progress, httpRequestMethods.GET, parm)
  }
}

export const ucenterRequest = {
  updateAuthorities (id, parm) {
    return request(url.ucenter.authorities(id), httpRequestMethods.POST, parm)
  },
  getAuthorities (parm) {
    return request(url.ucenter.users, httpRequestMethods.GET, parm)
  }
}

// task
export const taskRequest = Object.assign(generateBasisDataRequest('Task'), {
  get (parm) {
    return request(url.task.get, httpRequestMethods.GET, parm)
  },
  count (parm) {
    return request(url.task.count, httpRequestMethods.GET, parm)
  },
  details (parm) {
    return request(url.task.details, httpRequestMethods.GET, parm)
  },
  getHis (parm) {
    return request(url.task.getHis, httpRequestMethods.GET, parm)
  }
})

// task_reply
export const taskReplyRequest = Object.assign(generateBasisDataRequest('TaskReply'), {
  get (parm) {
    return refQueryRequestHander('get_task_reply', parm)
  }
})

// cmdType
export const cmdTypeRequest = generateBasisDataRequest('CmdType')

// serverCmdType
export const serverCmdTypeRequest = Object.assign(generateBasisDataRequest('ServerCmdType'), {
  execute (parm) {
    return request(url.serverCmdType.execute, httpRequestMethods.POST, parm)
  },
  get (parm) {
    return refQueryRequestHander('get_server_cmd_type', parm)
  }
})

// serverCmdLog
export const serverCmdLogRequest = {
  get (parm) {
    return refQueryRequestHander('get_server_cmd_log', parm)
  }
}

// dbOperationLog
export const dbOperationLogRequest = {
  get (parm) {
    return refQueryRequestHander('get_db_operation_log', parm)
  }
}

// appServiceAccessConfig
export const appServiceAccessConfigRequest = Object.assign(generateBasisDataRequest('AppServiceAccessConfig'), {
  get (parm) {
    return refQueryRequestHander('get_app_service_access_config', parm)
  }
})

export const dataAuditLogReuqest = {
  get: function (parm) {
    return request(url.dataAuditLog.base, httpRequestMethods.GET, parm)
  }
}

export const workOperationReuqest = {
  getLog: function (parm) {
    return request(url.workOperation.log, httpRequestMethods.GET, parm)
  }
}

// meet
export const meetRequest = Object.assign(generateBasisDataRequest('Meet'), {})

// meetMemo
export const meetMemoRequest = Object.assign(generateBasisDataRequest('MeetMemo'), {
  get (parm) {
    return refQueryRequestHander('get_meet_memo', parm)
  }
})

export const blackboardRequest = {
  get () {
    return request('/work/data/Blackboard', httpRequestMethods.GET)
  },
  add (param) {
    return request('/work/data/Blackboard', httpRequestMethods.POST, param)
  },
  update (id, param) {
    return request('/work/data/Blackboard/' + id, httpRequestMethods.PUT, param)
  },
  del (id) {
    return request('/work/data/Blackboard/' + id, httpRequestMethods.DELETE)
  }
}
