<style lang="less">
  .fm-table-row {
    transition: background-color .3s;
  }
  .fm-table-row-active {
    background-color: #ebf7ff;
  }
  .fm-table-stripe {
    .fm-table-row:nth-of-type(even) {
      background-color: #FAFAFA;
      &.fm-table-row-active {
        background-color: #ebf7ff;
      }
    }
  }
  .fm-table-row-resize {
    visibility: hidden;
  }
</style>

<template>
  <tr class="fm-table-row" :style="style" :class="{
    'fm-table-row-active': isActive,
    'fm-table-row-resize': resize
  }" @click="onClick" @dblclick="onDbclick" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  data () {
    return {
      active: false,
      resize: false,
      height: null
    }
  },
  inject: ['table'],
  props: {
    rowIndex: {type: Number, default: -1},
    columnIndex: {type: Number, default: -1},
  },
  computed: {
    style () {
      return {
        height: this.height !== null ? (this.height + 'px') : ''
      }
    },
    activeRowIndex () {
      return this.table.activeRowIndex
    },
    currentRowIndex () {
      return this.table.currentRowIndex
    },
    isActive () {
      return this.activeRowIndex === this.rowIndex || this.currentRowIndex === this.rowIndex || this.active
    }
  },
  methods: {
    onDbclick () {
      this.table.$emit('on-row-dbclick', this.rowIndex)
    },
    onClick () {
      this.table.$emit('on-row-click', this.rowIndex)
    },
    onMouseenter () {
      this.active = true
      this.table.$emit('on-row-hover', this.rowIndex)
    },
    onMouseleave () {
      this.active = false
      this.table.$emit('on-row-hover', null)
    },
    onWidthChange () {
      this.resize = true
      this.$nextTick(() => {
        this.table.$emit('on-row-resize', this.columnIndex, this.rowIndex, this.$el.offsetHeight)
      })
    },
    onSetHeight (index, height) {
      if (this.rowIndex === index) {
        this.height = height
        this.$nextTick(() => {
          this.resize = false
        })
      }
    }
  },
  mounted () {
    if (this.table.isFixed) {
      this.table.$on('set-cell-width', this.onWidthChange)
      this.table.$on('set-row-height', this.onSetHeight)
    } else {
      this.resize = false
    }
  }
}
</script>
