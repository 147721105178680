import {
  menuRequest,
  roleRequest,
  userRequest,
  serverRequest,
  appRequest,
  sysRequest,
  installTypeRequest,
  softRequest,
  serverAppInstallRequest,
  restartTypeRequest,
  cmdTypeRequest,
  sysInstanceRequest,
  appServiceRequest,
  appVersionRequest,
  sysVersionRequest,
  orgRequest
} from '@/api'

import {
  tools
} from '@/fmlib'

const dealData = (data, key, label) => {
  let newData = []
  data.forEach((item) => {
    let itemLabel = null

    if (Array.isArray(label)) {
      itemLabel = label.map(v => item[v]).join(' ')
    } else {
      itemLabel = item[label]
    }

    newData.push({
      key: item[key],
      label: itemLabel,
      data: item
    })
  })
  return newData
}

const listToSelectList = (data) => {
  let newData = []
  data.forEach((item) => {
    newData.push({key: item, label: item})
  })
  return newData
}

const state = {
  // 正在加载的数据
  baseDataLoading: {},
  // 已加载数据
  baseDataLoaded: {},
  // 菜单
  menuList: [],
  // 角色
  roleList: [],
  // appType
  appTypeList: listToSelectList(['java', 'python', 'web', 'mysql', 'nginx', 'redis', '其他']),
  // 菜单类型
  menuTypeList: [{key: 'menu', label: '菜单'}, {key: 'sys_page', label: '系统页面'}, {key: 'out_page', label: '外部链接'}],
  // 客户端类型
  clientTypeList: [{key: 'web', label: '电脑'}, {key: 'wap', label: '手机'}],
  // 当前客户端类型
  clientType: navigator.userAgent.match(/Android|iPhone|iPad|iPod/) === null ? 'web' : 'wap',
  // 版本状态
  versionStatusList: listToSelectList(['规划中', '实现中', '已发布', '已作废']),
  // 需求类型
  demandTypeList: listToSelectList(['新增功能', '功能优化', 'bug', '功能调整', '功能深化']),
  // 任务状态
  taskStatus: listToSelectList(['规划', '待实现', '实现中', '已实现', '已作废']),
  // 需求类型
  featuresTypeList: listToSelectList(['定时数据处理', '功能页面', '其他']),
  // 数据表字段类型
  tableFieldTypeList: listToSelectList(['number', 'float', 'text', 'chart', 'json']),
  // restartType
  restartTypeList: [],
  // installType
  installTypeList: [],
  // orgList
  orgList: [],
  // sysList
  sysList: [],
  // sysVersionList
  sysVersionList: [],
  // sysInstanceList
  sysInstanceList: [],
  // cmdType
  cmdTypeList: [],
  // appList
  appList: [],
  // appVersionList
  appVersionList: [],
  // appServiceList
  appServiceList: [],
  // server
  serverList: [],
  // user
  userList: [],
  // appFileName
  appFileNameList: [],
  // sysParamsConf
  sysParamsConf: null,
  // softList
  softList: [],
  // isPop
  isPop: false
}

const loadData = async function ({commit, getters, reLoad, dataKey, request, mutation, dealSourceData, parm}) {
  if ((reLoad || !getters.baseDataLoaded[dataKey]) && !getters.baseDataLoading[dataKey]) {
    commit('updateBaseDataLoading', {key: dataKey, value: true})
    let dataList = await request(parm)
    commit(mutation, dealSourceData(dataList))
    commit('updateBaseDataLoaded', dataKey)
    commit('updateBaseDataLoading', {key: dataKey, value: false})
    return getters[dataKey]
  } else if (getters.baseDataLoading[dataKey]) {
    while (getters.baseDataLoading[dataKey]) {
      await tools.sleep(200)
    }
    return getters[dataKey]
  } else {
    return Promise.resolve(getters[dataKey])
  }
}

const getters = {
  baseDataLoading: state => state.baseDataLoading,
  baseDataLoaded: state => state.baseDataLoaded,
  menuList: state => state.menuList,
  sysParamsConf: state => state.sysParamsConf,
  appFileNameList: state => state.appFileNameList,
  roleList: state => state.roleList,
  appTypeList: state => state.appTypeList,
  menuTypeList: state => state.menuTypeList,
  clientTypeList: state => state.clientTypeList,
  clientType: state => state.clientType,
  versionStatusList: state => state.versionStatusList,
  demandTypeList: state => state.demandTypeList,
  taskStatus: state => state.taskStatus,
  featuresTypeList: state => state.featuresTypeList,
  tableFieldTypeList: state => state.tableFieldTypeList,
  restartTypeList: state => state.restartTypeList,
  installTypeList: state => state.installTypeList,
  cmdTypeList: state => state.cmdTypeList,
  softList: state => state.softList,
  orgList: state => state.orgList,
  sysList: state => state.sysList,
  sysVersionList: state => state.sysVersionList,
  sysInstanceList: state => state.sysInstanceList,
  appList: state => state.appList,
  appVersionList: state => state.appVersionList,
  appServiceList: state => state.appServiceList,
  serverList: state => state.serverList,
  userList: state => state.userList,
  isPop: state => state.isPop
}

const actions = {
  loadSysParamsConf ({ commit }) {
    serverAppInstallRequest.getParamsConf().then((data) => {
      commit('setSysParamsConf', data)
    })
  },
  loadAppFileNameList ({ dispatch }) {
    serverAppInstallRequest.getFileName().then((data) => {
      dispatch('setAppFileNameList', data)
    })
  },
  setAppFileNameList ({ commit }, data) {
    commit('setAppFileNameList', data.map((v) => {
      return {
        key: v,
        label: v
      }
    }))
  },
  async loadSysList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'sysList', request: sysRequest.get, mutation: 'setSysList', dealSourceData})
  },
  setSysList ({ commit }, data) {
    commit('setSysList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'sysList')
  },
  async loadOrgList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'orgList', request: orgRequest.get, mutation: 'setOrgList', dealSourceData})
  },
  setOrgList ({ commit }, data) {
    commit('setOrgList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'orgList')
  },
  async loadAppServiceList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['appName', 'serverName'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'appServiceList', request: appServiceRequest.get, mutation: 'setAppServiceList', dealSourceData})
  },
  setAppServiceList ({ commit }, data) {
    commit('setAppServiceList', dealData(data, 'id', ['appName', 'serverName']))
    commit('updateBaseDataLoaded', 'appServiceList')
  },
  async loadInstallTypeList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'installTypeList', request: installTypeRequest.get, mutation: 'setInstallTypeList', dealSourceData})
  },
  setInstallTypeList ({ commit }, data) {
    commit('setInstallTypeList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'installTypeList')
  },
  async loadSoftList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['name', 'version'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'softList', request: softRequest.get, mutation: 'setSoftList', dealSourceData})
  },
  setSoftList ({ commit }, data) {
    commit('setSoftList', dealData(data, 'id', ['name', 'version']))
    commit('updateBaseDataLoaded', 'softList')
  },
  async loadSysVersionList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['sysName', 'versionNumber'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'sysVersionList', request: sysVersionRequest.get, mutation: 'setSysVersionList', dealSourceData})
  },
  setSysVersionList ({ commit }, data) {
    commit('setSysVersionList', dealData(data, 'id', ['sysName', 'versionNumber']))
    commit('updateBaseDataLoaded', 'sysVersionList')
  },
  async loadSysInstanceList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      let newData = []
      dataList.forEach((item) => {
        newData.push({
          key: item.id,
          label: (item.useOrgName || item.user) + ' ' + item.sysName + ':' + item.sysVersionNumber + ' ' + item.type,
          data: item
        })
      })
      return newData
    }
    return await loadData({commit, getters, reLoad, dataKey: 'sysInstanceList', request: sysInstanceRequest.get, mutation: 'setSysInstanceList', dealSourceData})
  },
  setSysInstanceList ({ commit }, data) {
    let dealSourceData = (dataList) => {
      let newData = []
      dataList.forEach((item) => {
        newData.push({
          key: item.id,
          label: (item.useOrgName || item.user) + ' ' + item.sysName + ':' + item.sysVersionNumber + ' ' + item.type,
          data: item
        })
      })
      return newData
    }
    commit('setSysInstanceList', dealSourceData(data))
    commit('updateBaseDataLoaded', 'sysInstanceList')
  },
  async loadAppList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'appList', request: appRequest.get, mutation: 'setAppList', dealSourceData})
  },
  setAppList ({ commit }, data) {
    commit('setAppList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'appList')
  },
  setIsPop ({ commit }, data) {
    commit('setIsPop', data)
  },
  async loadAppVersionList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['appName', 'versionNumber'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'appVersionList', request: appVersionRequest.get, mutation: 'setAppVersionList', dealSourceData})
  },
  setAppVersionList ({ commit }, data) {
    commit('setAppVersionList', dealData(data, 'id', ['appName', 'versionNumber']))
    commit('updateBaseDataLoaded', 'appVersionList')
  },
  async loadServerList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'serverList', request: serverRequest.get, mutation: 'setServerList', dealSourceData})
  },
  setServerList ({ commit }, data) {
    commit('setServerList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'serverList')
  },
  async loadUserList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'userList', request: userRequest.get, mutation: 'setUserList', dealSourceData})
  },
  setUserList ({ commit }, data) {
    commit('setUserList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'userList')
  },
  async loadRoleList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'roleList', request: roleRequest.get, mutation: 'setRoleList', dealSourceData})
  },
  setRoleList ({ commit }, data) {
    commit('setRoleList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'roleList')
  },
  async loadMenuList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList = tools.treeToList(dataList, 'children')
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'menuList', request: menuRequest.get, mutation: 'setMenuList', dealSourceData})
  },
  setMenuList ({ commit }, data) {
    commit('setMenuList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'menuList')
  },
  async loadRestartTypeList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'restartTypeList', request: restartTypeRequest.get, mutation: 'setRestartTypeList', dealSourceData})
  },
  setRestartTypeList ({ commit }, data) {
    commit('setRestartTypeList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'restartTypeList')
  },
  async loadCmdTypeList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'cmdTypeList', request: cmdTypeRequest.get, mutation: 'setCmdTypeList', dealSourceData})
  },
  setCmdTypeList ({ commit }, data) {
    commit('setCmdTypeList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'cmdTypeList')
  }
}

const mutations = {
  updateBaseDataLoading (state, {key, value}) {
    state.baseDataLoading[key] = value
  },
  updateBaseDataLoaded (state, key) {
    state.baseDataLoaded[key] = true
  },
  setAppServiceList (state, data) {
    state.appServiceList = data
  },
  setIsPop (state, data) {
    state.isPop = data
  },
  setSoftList (state, data) {
    state.softList = data
  },
  setSysParamsConf (state, data) {
    state.sysParamsConf = data
  },
  setInstallTypeList (state, data) {
    state.installTypeList = data
  },
  setAppFileNameList (state, data) {
    state.appFileNameList = data
  },
  setSysList (state, data) {
    state.sysList = data
  },
  setOrgList (state, data) {
    state.orgList = data
  },
  setSysVersionList (state, data) {
    state.sysVersionList = data
  },
  setSysInstanceList (state, data) {
    state.sysInstanceList = data
  },
  setAppList (state, data) {
    state.appList = data
  },
  setAppVersionList (state, data) {
    state.appVersionList = data
  },
  setServerList (state, data) {
    state.serverList = data
  },
  setMenuList (state, data) {
    state.menuList = data
  },
  setRoleList (state, data) {
    state.roleList = data
  },
  setUserList (state, data) {
    state.userList = data
  },
  setRestartTypeList (state, data) {
    state.restartTypeList = data
  },
  setCmdTypeList (state, data) {
    state.cmdTypeList = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
