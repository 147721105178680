<template>
  <router-view />
</template>

<script>
export default {}
</script>

<style lang="less">
@import './styles/global.less';
@import './styles/css-components.less';
@import url('//at.alicdn.com/t/font_1548313_k400g13skd.css');
</style>
