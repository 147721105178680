// fupaiTest 福牌测试环境
// fupaiPro 福牌正式环境

export const envs = {
  dev: {
    env: 'work',
    webPort: '80',
    hostName: 'work.e-forward.cn',
    apiAdr: 'https://api.work.e-forward.cn',
    remark: '工作平台'
  }
}
  
const initEnv = () => {
  // 根据前端接口来判断环境
  let env = null
  let hostName = window.location.hostname
  for (let key in envs) {
    if (hostName.includes(envs[key].hostName)) {
      env = envs[key]
      break
    }
  }
  if (!env) {
    let locationPort = window.location.port
    for (let key in envs) {
      if (envs[key].webPort === locationPort) {
        env = envs[key]
        break
      }
    }
  }
  console.log(env ? ('环境:' + env.remark) : '获取环境失败, 使用' + envs['dev'].remark)
  return env || envs['dev']
}

export default initEnv()
